define("isolocity/pods/planning-reports/-graphs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DXLH4rgE",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"translationKey\"],[\"planning-reports.headers.Reports by Month\"]]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n\\t\\t\"],[10,\"label\"],[14,\"for\",\"planning-type\"],[12],[2,\"Type\"],[13],[2,\"\\n\\t\\t\"],[1,[30,[36,3],null,[[\"value\",\"id\",\"preventNew\",\"allowBlank\",\"items\",\"placeholder\",\"clearOnExpand\",\"allowBlank\"],[[35,2],\"planning-types\",true,true,[35,1],\"Select...\",false,true]]]],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\t\"],[1,[30,[36,4],null,[[\"selectedPlanningType\"],[[35,2]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t-header\",\"graphTypes\",\"selectedPlanningType\",\"auto-complete\",\"graphs/planning-reports/historical-chart\"]}",
    "meta": {
      "moduleName": "isolocity/pods/planning-reports/-graphs/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/mixins/e-signature-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    modules: Ember.inject.service('modules'),
    eSignaturePassword: '',
    eSignatureReason: '',
    isAuthorizeReject: false,
    isCustomReason: false,
    actions: {
      eSignature: function eSignature(actionName) {
        var _this = this;

        var otherData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var isRequired = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var defaultReason = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
        var cancelAction = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

        if (!isRequired) {
          this.send(actionName, otherData);
          return;
        }

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this.send(actionName, otherData);

          _this.set('eSignaturePopup', null);
        }, function () {
          _this.set('eSignaturePopup', null);

          if (cancelAction) {
            _this.send(cancelAction);
          }
        }); // Here we clear the password and reason everytime the modal is shown,
        // to ensure that it must always be re-entered.

        this.send('clearEsignature');

        if (!Ember.isBlank(defaultReason)) {
          this.set('eSignatureReason', defaultReason);
        } // TODO: Review logic below


        if (actionName == 'authorize' || actionName == 'reject') {
          if (actionName == 'authorize') {
            this.set('eSignatureReason', 'Authorization');
          }

          if (actionName == 'reject') {
            this.set('eSignatureReason', 'Rejection');
          }

          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'save' && otherData == 'close') {
          this.set('eSignatureReason', 'Final Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'saveAndClose' && otherData == 'close') {
          this.set('eSignatureReason', 'Final Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'toggleActionItemCompleted') {
          this.set('eSignatureReason', 'Final Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'authorizeBudget') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'authorizeBudget') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'doEsignature') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'approveApproval' || actionName == 'approveVerification' || actionName == 'authorizePreventative') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'authorizePermitter') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'authorizeRecommender') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'approve') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'markInvestigated') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'remove') {
          this.set('eSignatureReason', 'Authorization');
          this.set('isAuthorizeReject', true);
        }

        if (actionName == 'save' && otherData == null) {
          this.set('isReasonRequired', this.get('modules').getModuleSetting('inspections', 'is_reason_required_on_save', false));
        }

        this.set('eSignaturePopup', defer);
      },
      addEsignature: function addEsignature(signature) {
        this.send('eSignature', 'doEsignature', signature, true);
      },
      clearEsignature: function clearEsignature() {
        this.set('eSignaturePassword', '');
        this.set('eSignatureReason', '');
        this.set('isCustomReason', false);
      },
      customReason: function customReason() {
        this.set('eSignatureReason', '');
        this.set('isCustomReason', true);
      },
      addResponsibility: function addResponsibility(esignature, responsibility) {
        var _this2 = this;

        var fm = this.get('flashMessages');

        if (Ember.isBlank(responsibility)) {
          fm.danger('Please enter the responsibility');
          return;
        }

        if (Ember.isBlank(esignature.get('responsibilities'))) {
          esignature.set('responsibilities', []);
        }

        var authHeaders = this.get('authHeaders');
        var responsibilities = esignature.get('responsibilities');
        responsibilities.push(responsibility);
        esignature.set('responsibilities', responsibilities);
        esignature.notifyPropertyChange('responsibilities');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/add-department-responsibility/' + esignature.get('id')),
          data: {
            responsibilities: responsibilities
          },
          method: 'POST'
        }).then(function () {
          _this2.get('model').reload();
        });
      },
      removeResponsibility: function removeResponsibility(esignature, responsibility) {
        var responsibilities = esignature.get('responsibilities');
        responsibilities = responsibilities.filter(function (item) {
          return item != responsibility;
        });
        esignature.set('responsibilities', responsibilities);
        esignature.notifyPropertyChange('responsibilities');
        var authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/remove-department-responsibility/' + esignature.get('id')),
          data: {
            responsibilities: responsibilities
          },
          method: 'POST'
        });
      },
      doEsignature: function doEsignature(esignature) {
        var _this3 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders'),
            responsibilities = esignature.get('responsibilities');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/esignature/' + esignature.get('id') + '/sign'),
          data: {
            responsibilities: responsibilities,
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          _this3.get('model').reload();
        }).fail(function () {
          fm.danger('Please ensure your password is correct. Only the department manager can e-sign here.');
        }).always(function () {
          _this3.set('eSignaturePassword', '');
        });
      },
      removeEsignature: function removeEsignature(signature) {
        this.send('eSignature', 'undoEsignature', signature, true);
      },
      undoEsignature: function undoEsignature(esignature) {
        var _this4 = this;

        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders'),
            responsibilities = esignature.get('responsibilities');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/esignature/' + esignature.get('id') + '/sign'),
          data: {
            responsibilities: responsibilities,
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function () {
          _this4.get('model').reload();
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger('You are not authorized to perform this action');
          }
        }).always(function () {
          _this4.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});
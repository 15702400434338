define("isolocity/mixins/parts-creation-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');
        var model = this.get('model');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.acceptance_ratio'))) {
            model.set('acceptance_ratio', 100);
          }

          this.set('clonePart', null);
          this.set('isLoading', true);
          model.save().then(function (part) {
            if (part.get('isCloning')) {
              _this.set('isClonePart', true);
            }

            _this.notifyPropertyChange('hasChangedUnitOfMeasure');

            if (model.get('materials.length') === 0) {
              _this.send('saveAttachments', part);
            } else {
              var materials = part.get('materials');
              var order = 1;
              var materialsNum = 0;
              var totalMaterials = materials.get('length');
              materials.forEach(function (material) {
                materialsNum++;

                if (_this.get('isClonePart') && materialsNum <= totalMaterials / 2) {
                  // Half is from parent clone part, so only edit other half
                  material.set('entity_id', part.get('id'));
                  material.set('order', order);
                  order++;
                }

                if (_this.get('isClonePart') == false) {
                  material.set('entity_id', part.get('id'));
                  material.set('order', order);
                  order++;
                }

                if (material === model.get('materials.lastObject')) {
                  material.save().then(function (saved) {
                    _this.send('saveAttachments', part);
                  });
                } else {
                  material.save();
                }
              });
            }
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(part) {
        var fm = this.get('flashMessages');
        this.set('isClonePart', false);
        this.set('isLoading', false);
        this.get('history').setRoute('parts');
        this.transitionToRoute('parts.edit', part.get('id'));
        fm.success('Your changes were successfully saved');
      },
      updateStatus: function updateStatus() {},
      savePart: function savePart() {},
      handleUnitTypeChanged: function handleUnitTypeChanged() {
        this.set('model.unit_of_measure', '');
      },
      toggleClonePart: function toggleClonePart() {
        this.set('isClonePart', true);
      },
      selectedClonePart: function selectedClonePart() {
        this.send('clonePart', this.get('clonePart'));
      }
    }
  });

  _exports.default = _default;
});
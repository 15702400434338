define("isolocity/pods/planning-reports/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FLqKrGFM",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,8],null,[[\"modelName\",\"isModelLoading\",\"model\"],[\"planning-report\",[35,7],[35,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0],\"list\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[19,\"planning-reports/list\",[1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[35,0],\"graphs\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[19,\"planning-reports/graphs\",[1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[\"user\"],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"col floating-action-buttons\"],[12],[2,\"\\n                \"],[11,\"a\"],[24,\"title\",\"List\"],[24,6,\"#\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],\"list\"],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"setViewMode\",\"list\"],null],[12],[2,\"\\n                    \"],[10,\"i\"],[14,0,\"fa fa-table fa-2x\"],[12],[13],[2,\"\\n                    \"],[10,\"p\"],[14,0,\"text-center\"],[12],[1,[30,[36,4],[\"sidebar.List\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[11,\"a\"],[24,\"title\",\"Graphs\"],[24,6,\"#\"],[16,0,[31,[[30,[36,2],[[30,[36,1],[[35,0],\"graphs\"],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"setViewMode\",\"graphs\"],null],[12],[2,\"\\n                    \"],[10,\"i\"],[14,0,\"fa fa-pie-chart fa-2x\"],[12],[13],[2,\"\\n                    \"],[10,\"p\"],[14,0,\"text-center\"],[12],[1,[30,[36,4],[\"sidebar.Graphs\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":true,\"upvars\":[\"viewMode\",\"equals\",\"if\",\"action\",\"t\",\"min-role\",\"sortedReports\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/planning-reports/index/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/inspections/activities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NoYelEo6",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,11],null,[[\"singular\",\"plural\",\"modelName\",\"isModelLoading\",\"model\"],[\"Activity Inspection\",\"Activity Inspections\",\"inspection\",[35,10],[35,9]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n            \"],[19,\"inspections/search\",[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-quarter col-create-btn\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",\"inspections.activity\"]],[[\"default\"],[{\"statements\":[[2,\"                Create Activity Inspection\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,8],null,[[\"listType\",\"currentPage\",\"totalPages\",\"totalRecords\",\"previousPage\",\"nextPage\",\"filterSuffix\"],[\"inspections\",[35,7],[35,6],[35,5],[30,[36,4],[[32,0],\"previousPage\"],null],[30,[36,4],[[32,0],\"nextPage\"],null],[30,[36,3],[[35,2],[35,1]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n    \"],[19,\"inspections/list\",[1]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":true,\"upvars\":[\"link-to\",\"searchDescriptor\",\"searchTypeDescriptor\",\"concat\",\"action\",\"totalRecords\",\"totalPages\",\"currentPage\",\"pagination\",\"sortedModel\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/inspections/activities/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/components/graphs/documents/approved-vs-unapproved-documents/component", ["exports", "isolocity/config/environment", "isolocity/pods/components/graphs/documents/approved-vs-unapproved-documents/chart", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _chart, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_chart.default, _ajaxRequestMixin.default, {
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    reportingService: Ember.inject.service('reporting'),
    init: function init() {
      this.set('chartData', null);

      var _this = this;

      var startDate = null,
          endDate = null;

      if (!Ember.isEmpty(this.get('startDateApprovedUnapproved'))) {
        startDate = moment(this.get('startDateApprovedUnapproved')).format("YYYY-MM-DD");
      }

      if (!Ember.isEmpty(this.get('endDateApprovedUnapproved'))) {
        endDate = moment(this.get('endDateApprovedUnapproved')).format("YYYY-MM-DD");
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/reports/documents/approved-unapproved/graph',
        method: 'GET',
        data: {
          company_ids: this.get('reportingService').get('companies'),
          start_date: startDate,
          end_date: endDate
        },
        success: function success(data) {
          if (_this.get('isDestroyed')) return;

          var onApprovedLabel = _this.get('intl').t('Approved');

          var onUnapprovedLabel = _this.get('intl').t('Unapproved');

          var series = [{
            name: 'Percentage',
            colorByPoint: true,
            data: [{
              name: onApprovedLabel,
              y: data.approved,
              selected: true,
              color: '#69c576'
            }, {
              name: onUnapprovedLabel,
              y: data.unapproved,
              color: '#ed6161'
            }]
          }];

          if (!Ember.isBlank(series) && (data.approved > 0 || data.unapproved > 0)) {
            _this.set('chartData', series);
          } else {
            _this.set('chartData', [{
              name: 'No Data',
              data: []
            }]);
          }
        }
      });

      this._super.apply(this, arguments);
    },
    onStartDatePreventativeChange: Ember.observer('startDateApprovedUnapproved', function () {
      this.init();
    }),
    onEndDatePreventativeChange: Ember.observer('endDateApprovedUnapproved', function () {
      this.init();
    })
  });

  _exports.default = _default;
});
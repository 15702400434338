define("isolocity/pods/index/route", ["exports", "isolocity/mixins/localization-mixin", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _localizationMixin, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _localizationMixin.default, {
    isVisibleAllUsers: true,
    permissions: Ember.inject.service('user-permissions'),
    modelName: ['action-item', 'training-schedule-employee', 'test.result'],
    model: function model() {
      return Ember.RSVP.hash({
        modules: this.store.findAll('module')
      });
    },
    afterModel: function afterModel(model, transition) {
      model.myIncompleteActionItems = this.store.query('action-item', {
        mine: true,
        completed: false
      });
      model.myEnrollments = this.store.query('training-schedule-employee', {
        mine: true
      });
      model.myTests = this.store.query('test.result', {
        mine: true
      });

      if (this.get('permissions').hasRoleAtLeast('user')) {
        model.parts = this.store.findAll('part');
        model.errorCodes = this.store.findAll('error-code');
        model.faqs = this.store.findAll('faq');
        model.companies = this.store.findAll('company');
        model.companyGroups = this.store.findAll('company-group');
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/companies/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c0bEkPxt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,2],[[35,1],\"name\"],null]]]],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"id\",\"placeholder\"],[[35,3,[\"name\"]],[30,[36,2],[[35,1],\"name\"],null],\"Enter the team's name\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,2],[[35,1],\"status\"],null]]]],[12],[2,\"Status\"],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"id\",\"status\"],[[30,[36,2],[[35,1],\"status\"],null],[35,3,[\"is_active\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,2],[[35,1],\"timezone\"],null]]]],[12],[2,\"Timezone\"],[13],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"value\",\"items\",\"preventNew\"],[[35,3,[\"timezone\"]],[35,6],true]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-quarter\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"order_number\"],[12],[2,\"Order Number Start\"],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"min\",\"value\"],[\"number\",\"0\",[35,3,[\"settings\",\"order_start_number\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"id_prefix\",\"concat\",\"model\",\"input\",\"active-inactive-toggle\",\"validTimezones\",\"auto-complete\"]}",
    "meta": {
      "moduleName": "isolocity/pods/companies/-form/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/corrective-actions/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/corrective-action-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _dateSearchMixin, _downloadableReportsMixin, _correctiveActionMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_downloadableReportsMixin.default, _correctiveActionMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    viewMode: 'list',
    startDate: null,
    endDate: null,
    searchStatus: null,
    searchCarSource: null,
    searchCarType: null,
    searchErrorCode: null,
    searchOwner: null,
    permissions: Ember.inject.service('user-permissions'),
    search: Ember.inject.service('search.cars'),
    sortedModel: Ember.computed.sort('model.cars', 'sortProperties'),
    sortProperties: ['has_incomplete_action_items:desc', 'has_unread_notes:desc', 'is_closed:asc'],
    carOverviewOptions: ['By Supplier', 'By Customer', 'By Error Code', 'By Car Type'],
    costOverviewOptions: ['Error Code', 'Part'],
    paretoOverviewOptions: ['Error Code', 'Part', 'Car Type'],
    selectedParetoOverview: 'Error Code',
    reportDateRange: Ember.computed('startDate', 'endDate', function () {
      return {
        start_date: this.get('startDate') ? moment(this.get('startDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('endDate') ? moment(this.get('endDate')).format("YYYY-MM-DD") : null
      };
    }),
    costOfQualityCriteria: Ember.computed('selectedCostPart', 'selectedCostErrorCode', function () {
      return {
        part_id: !Ember.isEmpty(this.get('selectedCostPart')) ? this.get('selectedCostPart.id') : null,
        error_code_id: !Ember.isEmpty(this.get('selectedCostErrorCode')) ? this.get('selectedCostErrorCode.id') : null
      };
    }),
    canCreateCar: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('manager');
    }),
    newPath: Ember.computed('canCreateCar', function () {
      return this.get('canCreateCar') ? 'corrective-actions.new' : false;
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setStatus(this.get('searchStatus'));
        this.get('search').setCarSource(this.get('searchCarSource.id'));
        this.get('search').setCarType(this.get('searchCarType.id'));
        this.get('search').setErrorCode(this.get('searchErrorCode.id'));
        this.get('search').setOwner(this.get('searchOwner.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('searchStatus', null);
        this.set('searchCarSource', null);
        this.set('searchCarType', null);
        this.set('searchErrorCode', null);
        this.set('searchOwner', null);
      },
      setViewMode: function setViewMode(mode) {
        if (mode !== 'list') {
          this.set('disableHeaderButton', true);
          this.set('hideFilterFields', true);
        } else {
          this.set('disableHeaderButton', false);
          this.set('hideFilterFields', false);
        } // Load resources only for Graphs View


        if (mode == 'graphs') {
          this.set('model.suppliers', this.store.findAll('supplier'));
          this.set('model.customers', this.store.findAll('customer'));
          this.set('model.parts', this.store.findAll('part'));
        }

        this.set('viewMode', mode);
      },
      handleSelectedCarOverview: function handleSelectedCarOverview() {
        if (this.get('selectedCarOverview') === 'By Supplier') {
          this.set('selectedSupplier', null);
        }

        if (this.get('selectedCarOverview') === 'By Customer') {
          this.set('selectedCustomer', null);
        }

        if (this.get('selectedCarOverview') === 'By Error Code') {
          this.set('selectedCarErrorCode', null);
        }

        if (this.get('selectedCarOverview') === 'By Car Type') {
          this.set('selectedCarType', null);
        }
      },
      handleSelectedSupplier: function handleSelectedSupplier() {
        if (this.get('selectedCarOverview') === 'By Supplier') {
          this.set('selectedCarOverview', null);
        }

        this.set('selectedCustomer', null);
      },
      handleSelectedCustomer: function handleSelectedCustomer() {
        if (this.get('selectedCarOverview') === 'By Customer') {
          this.set('selectedCarOverview', null);
        }

        this.set('selectedSupplier', null);
      },
      handleSelectedCostOverview: function handleSelectedCostOverview() {
        this.set('selectedCostPart', null);
        this.set('selectedCostErrorCode', null);
      },
      handleSelectedCostPart: function handleSelectedCostPart() {
        this.set('selectedCostOverview', null);
        this.set('selectedCostErrorCode', null);
      },
      handleSelectedCostErrorCode: function handleSelectedCostErrorCode() {
        this.set('selectedCostOverview', null);
        this.set('selectedCostPart', null);
      },
      handleSelectedParetoOverview: function handleSelectedParetoOverview() {
        if (this.get('selectedParetoOverview') === 'Error Code') {
          this.set('selectedParetoErrorCode', null);
        }

        if (this.get('selectedParetoOverview') === 'Part') {
          this.set('selectedParetoPart', null);
        }
      },
      handleSelectedParetoPart: function handleSelectedParetoPart() {
        this.set('selectedParetoOverview', 'Error Code');
        this.set('selectedParetoErrorCode', null);
      },
      handleSelectedParetoErrorCode: function handleSelectedParetoErrorCode() {
        this.set('selectedParetoOverview', 'Part');
        this.set('selectedParetoPart', null);
      }
    }
  });

  _exports.default = _default;
});
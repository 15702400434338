define("isolocity/pods/application/route", ["exports", "isolocity/config/environment", "isolocity/mixins/inspectlet-app-mixin", "ember-simple-auth/mixins/application-route-mixin", "isolocity/mixins/logout-handler-mixin", "isolocity/mixins/sentry-initializer-mixin", "isolocity/mixins/user-inactivity-tracker-mixin", "isolocity/mixins/walkme-mixin", "isolocity/mixins/localization-mixin", "isolocity/mixins/freshchat-mixin"], function (_exports, _environment, _inspectletAppMixin, _applicationRouteMixin, _logoutHandlerMixin, _sentryInitializerMixin, _userInactivityTrackerMixin, _walkmeMixin, _localizationMixin, _freshchatMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // For PENDO
  var _default = Ember.Route.extend(_applicationRouteMixin.default, _logoutHandlerMixin.default, _sentryInitializerMixin.default, _walkmeMixin.default, _localizationMixin.default, _userInactivityTrackerMixin.default, _inspectletAppMixin.default, _freshchatMixin.default, {
    raven: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    sideMenu: Ember.inject.service(),
    routeAfterAuthentication: 'index',
    closeSideMenus: function closeSideMenus() {
      this.get('sideMenu').close();
    },
    beforeModel: function beforeModel() {
      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        this.getTranslations();
      }
    },
    model: function model() {
      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        this.setRavenContext();
        this.setWalkMeVars();

        if (_environment.default.environment == 'uat') {
          this.initInspectletApp(); // Temporal Testing Inspectlet Integration
        }

        if (_environment.default.environment == 'uat') {
          this.loadFreshchatScript(); // Temporal Testing Freshchat Integration
        }

        return this.store.findAll('module');
      }

      return null;
    },
    actions: {
      willTransition: function willTransition(transition) {
        this.setRavenContext();
        this.setWalkMeVars();

        if (_environment.default.environment == 'uat') {
          this.initInspectletApp(); // Temporal Testing Inspectlet Integration
        }

        if (_environment.default.environment == 'uat') {
          this.initializeFreshchat(); // Temporal Testing Freshchat Integration
        }

        this.startInactivityTracker();
        this.closeSideMenus();
      },
      didTransition: function didTransition(transition) {
        var queue = this.get('flashMessages.queue');

        if (queue.get('length') > 0) {
          var removalQueue = [];
          queue.forEach(function (message) {
            if (message.get('type') === 'danger' || message.get('type') === 'correctiveAction') {
              removalQueue.push(message);
            }
          });
          queue.removeObjects(removalQueue);
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/mixins/document-handler-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    authManager: Ember.inject.service('session'),
    documentTypes: [],
    searchText: '',
    searchType: '',
    searchDepartment: '',
    bold: function bold(str) {
      return '<strong>' + str + '</strong>';
    },
    isFilteredResults: Ember.computed(function () {
      return this.get('searchText') || this.get('searchType') || this.get('searchDepartment');
    }),
    searchDescriptor: Ember.computed(function () {
      var descriptor = '';

      if (this.get('searchText')) {
        descriptor += ' matching "' + this.bold(this.get('searchText')) + '"';
      }

      if (this.get('searchType')) {
        descriptor += ' with type ' + this.bold(this.get('searchType.name'));
      }

      if (this.get('searchDepartment')) {
        descriptor += ' with department ' + this.bold(this.get('searchDepartment.name'));
      }

      return descriptor.htmlSafe();
    }),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchDocuments');
        }
      },
      searchDocuments: function searchDocuments() {
        this.get('search').setKeyword(this.get('searchText'));
        this.get('search').setType(this.get('selectedDocumentType.id'));
        this.get('search').setDepartment(this.get('selectedDepartment.id'));
        this.get('search').setStatus(this.get('status.id'));
        this.send('doSearchDocuments');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.set('searchType', '');
        this.set('status', '');
        this.set('searchDepartment', '');
        this.set('selectedDocumentType', null);
        this.set('selectedDepartment', null);
        this.get('search').clear();
        this.send('doSearchDocuments');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      toggleDocumentArchive: function toggleDocumentArchive(model) {
        model.toggleProperty('is_archived');
        model.save();
      },
      downloadFile: function downloadFile(id) {
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/files/' + id + '/download',
          data: {},
          method: 'GET',
          success: function success(data) {
            window.location = data;
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/suppliers/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/suppliers-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/ajax-request-mixin", "moment", "isolocity/mixins/owners-and-subscribers-mixin"], function (_exports, _environment, _suppliersMixin, _uploadFilesMixin, _historyHandlerMixin, _ajaxRequestMixin, _moment, _ownersAndSubscribersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_historyHandlerMixin.default, _uploadFilesMixin.default, _suppliersMixin.default, _ajaxRequestMixin.default, _ownersAndSubscribersMixin.default, {
    isLoading: false,
    isResendingCertificate: false,
    authManager: Ember.inject.service('session'),
    isEdit: true,
    today: (0, _moment.default)(),
    modules: Ember.inject.service('modules'),
    permissions: Ember.inject.service('user-permissions'),

    get module() {
      return this.get('modules').getModuleBySlug('suppliers');
    },

    readOnly: Ember.computed('model.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('admin') && this.get('module.settings.is_authorized_users') && !this.get('model.is_owned_by_current_user')) {
        return true;
      }

      return false;
    }),
    actions: {
      afterAttachmentsSaved: function afterAttachmentsSaved(supplier) {
        this.set('isLoading', false);
        var fm = this.get('flashMessages');
        this.get('history').setRoute('suppliers');
        this.transitionToRoute('suppliers.edit', supplier);
        fm.success("The supplier's details have been saved.");
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      },
      addCertificate: function addCertificate() {
        var certificate = this.store.createRecord('supplier.certificate', {
          supplier_id: this.get('model')
        });
        this.set('selectedCertificate', certificate);
        certificate.save();
      },
      resendCertificateEmail: function resendCertificateEmail() {
        var fm = this.get('flashMessages'),
            _this = this,
            authToken = this.get('authManager.session.content.authenticated.access_token'),
            model = this.get('model');

        this.set('isResendingCertificate', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/supplier/resend-email?token=' + authToken,
          data: {
            supplier_id: model.get('id')
          },
          method: 'POST',
          success: function success(data) {
            fm.success("The email has been re-sent to the supplier");
          }
        }).always(function () {
          _this.set('isResendingCertificate', false);
        });
      }
    }
  });

  _exports.default = _default;
});
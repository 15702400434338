define("isolocity/pods/password/change/controller", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    token: null,
    case: null,
    isNewPassword: false,
    init: function init() {
      var _$$urlParam;

      this._super.apply(this, arguments);

      $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        return results != null ? results[1] || 0 : null;
      };

      this.token = $.urlParam('token');
      this.case = (_$$urlParam = $.urlParam('case')) !== null && _$$urlParam !== void 0 ? _$$urlParam : null;
      this.isNewPassword = $.urlParam('is_new_password') === 'true';
    },
    actions: {
      submit: function submit() {
        var _this2 = this;

        var fm = this.get('flashMessages');
        var email = $('#email').val();
        var password = $('#password').val();
        var confirm = $('#confirm').val();
        var token = this.get('token');

        var _this = this;

        if (password !== confirm) {
          fm.danger('Your passwords don\'t match', {
            sticky: true
          });
        } else {
          fm.clearMessages();
          $.ajax({
            url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/authenticate/password-reset',
            type: 'post',
            data: {
              email: email,
              token: token,
              password: password,
              confirm: confirm
            },
            headers: {
              ApiKey: _environment.default.APP.apiKey
            },
            dataType: 'json',
            success: function success(data) {
              if (data.status == 'success') {
                fm.success('Your password has been reset. Try logging in again');
                setTimeout(function () {
                  _this2.transitionToRoute('login');
                }, 1500);
              } else {
                fm.danger(data.message);
              }
            }
          }).fail(function (data) {
            fm.danger(data.responseJSON.error);
          });
        }
      }
    }
  });

  _exports.default = _default;
});
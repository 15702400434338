define("isolocity/pods/action-items/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e8hNSXnR",
    "block": "{\"symbols\":[\"filteredModel\"],\"statements\":[[6,[37,15],null,[[\"isModelLoading\",\"model\"],[[35,14],[35,13,[\"actionItems\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"content-row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-three-quarter\"],[12],[2,\"\\n            \"],[19,\"action-items/search\",[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-quarter col-create-btn\"],[12],[2,\"\\n            \"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-go pull-right\",\"action-items.new\"]],[[\"default\"],[{\"statements\":[[2,\"Create Action Item\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,8],null,[[\"listType\",\"currentPage\",\"totalPages\",\"totalRecords\",\"previousPage\",\"nextPage\",\"filterSuffix\"],[\"Action-items\",[35,7],[35,6],[35,5],[30,[36,4],[[32,0],\"previousPage\"],null],[30,[36,4],[[32,0],\"nextPage\"],null],[30,[36,3],[[35,2],[35,1]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"    \"],[1,[30,[36,9],null,[[\"actionItems\",\"onActionItemToggled\",\"allowCreate\",\"isLinks\"],[[32,1],\"preToggleActionItemCompleted\",false,true]]]],[2,\"\\n\\n\"],[6,[37,12],null,[[\"eSignaturePassword\",\"eSignaturePopup\"],[[35,11],[35,10]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"    \\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"secondary-link\"],[12],[2,\"\\n    \"],[6,[37,0],null,[[\"route\"],[\"action-items.completed\"]],[[\"default\"],[{\"statements\":[[2,\"View Completed Action Items\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"link-to\",\"searchDescriptor\",\"searchTypeDescriptor\",\"concat\",\"action\",\"totalRecords\",\"totalPages\",\"currentPage\",\"pagination\",\"action-items-cards\",\"eSignaturePopup\",\"eSignaturePassword\",\"e-signature\",\"model\",\"isModelLoading\",\"model-index\"]}",
    "meta": {
      "moduleName": "isolocity/pods/action-items/index/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/documents/revision/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/document-links-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/document-users-mixin", "isolocity/mixins/document-editing-mixin", "isolocity/mixins/documents-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _documentLinksMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _documentUsersMixin, _documentEditingMixin, _documentsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _uploadFilesMixin.default, _documentEditingMixin.default, _documentLinksMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _documentUsersMixin.default, _documentsMixin.default, {
    id_prefix: 'revision_',
    modules: Ember.inject.service('modules'),
    permissions: Ember.inject.service('user-permissions'),
    isEdit: true,
    isSaving: false,
    isRevisionCreating: false,
    isApprovalCreating: false,
    noteEntityType: 'document',
    actionLink: 'documents.edit',
    actionEntityType: 'document',

    get isCreationForAdmin() {
      return this.get('modules').getModuleSetting('documents', 'is_creation_of_document_for_admin', false);
    },

    hasAdminLevelUser: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    actions: {
      createDocument: function createDocument() {
        if (this.get('isCreationForAdmin') && !this.get('permissions').hasRoleAtLeast('admin')) {
          var fm = this.get('flashMessages');
          fm.danger("Only admin level users can create a new document revision.");
        }
      }
    }
  });

  _exports.default = _default;
});
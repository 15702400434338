define("isolocity/mixins/test-result-pdf-mixin", ["exports", "ember-data", "isolocity/mixins/pdf-generation-mixin"], function (_exports, _emberData, _pdfGenerationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pdfGenerationMixin.default, {
    getTestResultReportContent: function getTestResultReportContent(result) {
      var _this = this;

      var showOverviewHeader = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var reportContent = [];
      var status = 'Incomplete';
      var style = 'progress';

      if (result.get('is_passed') === true) {
        status = 'Passed';
        style = 'passed';
      }

      if (result.get('is_passed') === false) {
        status = 'Failed';
        style = 'failed';
      }

      if (result.get('is_passed') === null || result.get('is_passed') === undefined) {
        status = 'Incomplete';
        style = 'closed';
      }

      if (showOverviewHeader) {
        reportContent.push(this.getSectionHeader('Overview'));
      }

      var docName = !Ember.isBlank(result.get('document_name')) ? result.get('document_name') : 'N/A';
      var departmentName = !Ember.isBlank(result.get('department_name')) ? result.get('department_name') : 'N/A';
      reportContent.push(this.getSectionTable(['20%', '20%', '20%', '20%', '20%'], ['Document', 'Department', 'Status', 'Completed By', 'Final Score'], [docName, departmentName, {
        text: status,
        style: style
      }, Ember.isEmpty(result.get('completed_at')) ? '' : result.get('completedAt') + ' by ' + result.get('taker_name'), Ember.isEmpty(result.get('final_score_as_percentage')) ? '0%' : result.get('final_score_as_percentage') + '%']));
      reportContent.push(this.getSectionHeader('Answers'));
      result.get('questions').forEach(function (resultQuestion) {
        var _resultQuestion$get;

        var answerClass = !Ember.isEmpty(resultQuestion.get('score')) && resultQuestion.get('score') > 0 ? 'passed' : 'failed';
        var answer = !Ember.isEmpty(resultQuestion.get('answer')) ? resultQuestion.get('answer') : '';
        var question_definitive = (_resultQuestion$get = resultQuestion.get('question_definitive')) !== null && _resultQuestion$get !== void 0 ? _resultQuestion$get : resultQuestion.get('question.title');
        reportContent.push(_this.getLine('Q: ' + question_definitive));
        reportContent.push(_this.getLine('A: ' + answer, ['bold', 'margins', answerClass]));
      });
      return reportContent;
    }
  });

  _exports.default = _default;
});
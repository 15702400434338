define("isolocity/pods/users/edit/controller", ["exports", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/timezones-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/user-employee-link-mixin"], function (_exports, _requiredFieldsMixin, _historyHandlerMixin, _timezonesMixin, _userOrganizationMixin, _userEmployeeLinkMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _timezonesMixin.default, _userOrganizationMixin.default, _userEmployeeLinkMixin.default, {
    id_prefix: 'edit_',
    isEdit: true,
    requiredFields: ['first_name', 'last_name', 'email', 'phone', 'primary_role_id.id'],
    selectedEmployee: null,
    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.companies'))) {
            fm.danger('At least one team is required');
            return;
          }

          this.get('model').save().then(function (user) {
            user.reload();
            fm.success("The user's details have been saved.");
          }, function (response) {
            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      onSelectCompany: function onSelectCompany(company) {
        var companies = this.get('model.companies');

        if (!companies.includes(company)) {
          this.get('model.companies').pushObject(company);
        }
      },
      onRemoveCompany: function onRemoveCompany(company) {
        var companies = this.get('model.companies');

        if (companies.includes(company)) {
          this.get('model.companies').removeObject(company);
        }

        if (this.get('model.current_company_id.id') == company.get('id')) {
          var remainingCompany = this.get('model.companies.firstObject');

          if (remainingCompany != null) {
            this.set('model.current_company_id', remainingCompany);
          }
        }

        if (Ember.isEmpty(this.get('model.companies.firstObject'))) {
          this.get('flashMessages').danger('At least one team is required');
        }
      },
      linkEmployeeProfile: function linkEmployeeProfile(user) {
        var _this2 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this2.send('doUserEmployeeLink', user, _this2.get('selectedEmployee'));

          _this2.set('linkUserEmployeePopup', null);
        }, function () {
          _this2.set('linkUserEmployeePopup', null);
        });
        this.set('isCreateEmployeeProfile', false);
        this.set('linkUserEmployeePopup', defer);
      },
      updateStatus: function updateStatus() {
        this.get('model').save();
      }
    }
  });

  _exports.default = _default;
});
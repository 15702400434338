define("isolocity/mixins/test-result-mixin", ["exports", "isolocity/config/environment", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    actions: {
      retakeTest: function retakeTest(result) {
        var fm = this.get('flashMessages'),
            _this = this;

        var authToken = this.get('authManager.session.content.authenticated.access_token');
        this.set('isResendingTest', true);
        result.set('isResendingTest', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/tests/' + result.get('test_id.id') + '/results/' + result.get('id') + '/retake?token=' + authToken,
          method: 'POST',
          success: function success(data) {
            _this.sendAction('onTestRetaken');

            _this.set('isResendingTest', false);

            result.set('isResendingTest', false);
            fm.success("A new test has been created and sent");
          }
        });
      },
      resendTest: function resendTest(result) {
        var fm = this.get('flashMessages');

        var _this = this;

        var authToken = this.get('authManager.session.content.authenticated.access_token');
        this.set('isResendingTestEmail', true);
        result.set('isResendingTestEmail', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/tests/' + result.get('test_id.id') + '/results/' + result.get('id') + '/resend?token=' + authToken,
          method: 'POST',
          success: function success(data) {
            _this.set('isResendingTestEmail', false);

            result.set('isResendingTestEmail', false);
            fm.success("The test email has been re-sent");
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/equipment/index/controller", ["exports", "isolocity/mixins/reporting-sidebar-mixin", "isolocity/mixins/equipment-handler-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _reportingSidebarMixin, _equipmentHandlerMixin, _downloadableReportsMixin, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportingSidebarMixin.default, _equipmentHandlerMixin.default, _downloadableReportsMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.equipments'),
    permissions: Ember.inject.service('user-permissions'),
    selectedEquipment: null,
    equipmentType: null,
    equipmentOverviewOptions: ['By Equipment'],
    isOutForService: {
      id: false,
      name: 'In Service'
    },
    serviceOptions: [{
      id: false,
      name: 'In Service'
    }, {
      id: true,
      name: 'Out of Service'
    }],
    isOverdue: null,
    overdueOptions: [{
      id: true,
      name: 'Overdue'
    }],
    isActive: {
      id: true,
      name: 'Active'
    },
    statusOptions: [{
      id: true,
      name: 'Active'
    }, {
      id: false,
      name: 'Inactive'
    }],
    costOfPartsCriteria: Ember.computed('selectedEquipmentForCost', 'selectedEquipmentOverview', function () {
      var criteria = {};

      if (!Ember.isEmpty(this.get('selectedEquipmentForCost'))) {
        criteria['equipment_id'] = this.get('selectedEquipmentForCost.id');
      }

      if (!Ember.isEmpty(this.get('selectedEquipmentOverview'))) {
        criteria['overview'] = this.get('selectedEquipmentOverview.id');
      }

      return criteria;
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setIsActive(this.get('isActive.id'));
        this.get('search').setIsOutForService(this.get('isOutForService.id'));
        this.get('search').setIsOverdue(this.get('isOverdue.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('isActive', {
          id: true,
          name: 'Active'
        });
        this.set('isOutForService', {
          id: false,
          name: 'In Service'
        });
        this.set('equipmentType', null);
      },
      handleSelectedCostEquipment: function handleSelectedCostEquipment() {}
    }
  });

  _exports.default = _default;
});
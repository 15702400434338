define("isolocity/pods/complaints/index/controller", ["exports", "isolocity/mixins/pagination-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/floating-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _paginationMixin, _dateSearchMixin, _floatingSidebarMixin, _downloadableReportsMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _floatingSidebarMixin.default, _downloadableReportsMixin.default, _paginationMixin.default, _localizationMixin.default, {
    intl: Ember.inject.service(),
    search: Ember.inject.service('search.complaints'),
    complaintOverviewOptions: [],
    isStatus: null,
    statusOptions: [{
      id: 'Open',
      name: 'Open'
    }, {
      id: 'Closed',
      name: 'Closed'
    }],
    searchDepartment: null,
    searchOwner: null,
    init: function init() {
      this.complaintOverviewOptions = [this.get('intl').t('lookups.By Part'), this.get('intl').t('lookups.By Error Code')];

      this._super.apply(this, arguments);
    },
    customReportData: Ember.computed('customStartDate', 'customEndDate', 'customStatus', 'customImpact', 'customPart', 'customDepartment', 'customErrorCode', function () {
      return {
        status: this.get('customStatus') ? this.get('customStatus') : null,
        impact: this.get('customImpact') ? this.get('customImpact.id') : null,
        part_id: this.get('customPart') ? this.get('customPart.id') : null,
        error_code_id: this.get('customErrorCode') ? this.get('customErrorCode.id') : null,
        department_id: this.get('customDepartment') ? this.get('customDepartment.id') : null,
        start_date: this.get('customStartDate') ? moment(this.get('customStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('customEndDate') ? moment(this.get('customEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setIsStatus(this.get('isStatus.id'));
        this.get('search').setDepartment(this.get('searchDepartment.id'));
        this.get('search').setOwner(this.get('searchOwner.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('isStatus', null);
        this.set('searchDepartment', null);
        this.set('searchOwner', null);
      }
    }
  });

  _exports.default = _default;
});
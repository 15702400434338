define("isolocity/pods/components/settings-sidebar/suppliers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GffTqyTN",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Only authorized users can edit suppliers details \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-half\"],[12],[2,\"\\n        \"],[8,\"x-toggle\",[],[[\"@theme\",\"@value\",\"@showLabels\",\"@offLabel\",\"@onLabel\",\"@onToggle\",\"@disabled\",\"@class\"],[\"light\",[34,0,[\"settings\",\"is_authorized_users\"]],false,\"No\",\"Yes\",[30,[36,2],[[30,[36,1],[[35,0,[\"settings\",\"is_authorized_users\"]]],null]],null],false,\"pull-left\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"module\",\"mut\",\"fn\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/settings-sidebar/suppliers/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/mixins/freshchat-mixin", ["exports", "isolocity/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    freshchatWidgetLoaded: false,
    // Track whether Freshchat is loaded
    // Inject the auth service to get user details
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Check if environment is development or UAT


      if (['development', 'uat'].includes(_environment.default.environment)) {
        this.loadFreshchatScript();
      }
    },
    loadFreshchatScript: function loadFreshchatScript() {
      var _this = this;

      // Check if the script is already added
      if (!document.getElementById('freshchat-script')) {
        var script = document.createElement('script');
        script.id = 'freshchat-script'; // To prevent multiple injections

        script.src = '//fw-cdn.com/11752667/4373758.js';
        script.setAttribute('chat', 'true');
        script.async = true;

        script.onload = function () {
          _this.initializeFreshchat(); // Initialize Freshchat after script loads

        };

        script.onerror = function () {
          console.error('Failed to load Freshchat script');
        };

        document.head.appendChild(script);
      }
    },
    initializeFreshchat: function initializeFreshchat() {
      var _this2 = this;

      var checkWidgetLoad = function checkWidgetLoad() {
        if (window.fcWidget) {
          _this2.set('freshchatWidgetLoaded', true);

          _this2.setUserDetails(); // Set user details after widget is loaded


          window.fcWidget.show();
        } else {
          console.log('Freshchat widget not loaded yet, retrying...');
          setTimeout(checkWidgetLoad, 1000); // Retry every second until ready
        }
      };

      checkWidgetLoad(); // Start checking for widget load
    },
    setUserDetails: function setUserDetails() {
      var currentUser = this.get('session.session.currentUser');

      if (currentUser) {
        // Attempt to set user details using Freshchat API
        try {
          if (window.fcWidget) {
            window.fcWidget.user.setFirstName(currentUser.name);
            window.fcWidget.user.setEmail(currentUser.email);
            window.fcWidget.setTags("2.0");
          }
        } catch (error) {
          console.error('Error setting Freshchat user details:', error);
        }
      } else {
        console.log('Freshchat not ready or user details not available');
      }
    },
    clearFreshchat: function clearFreshchat() {
      // Destroy the Freshchat widget
      if (window.fcWidget) {
        window.fcWidget.hide();
        window.fcWidget.user.clear();
        window.fcWidget.user.setProperties({});
        window.fcWidget.destroy(); // Completely remove the widget

        console.log('Freshchat widget destroyed');
      } else {
        console.error('Freshchat widget could not be destroyed');
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/services/search/deviations", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    isApproved: null,
    isPlanned: null,
    type: null,
    errorCode: null,
    owner: null,
    getIsApproved: function getIsApproved() {
      return this.get('isApproved');
    },
    setIsApproved: function setIsApproved(isApproved) {
      this.set('isApproved', isApproved);
    },
    getIsPlanned: function getIsPlanned() {
      return this.get('isPlanned');
    },
    setIsPlanned: function setIsPlanned(isPlanned) {
      this.set('isPlanned', isPlanned);
    },
    getDeviationType: function getDeviationType() {
      return this.get('type');
    },
    setDeviationType: function setDeviationType(type) {
      this.set('type', type);
    },
    getErrorCode: function getErrorCode() {
      return this.get('errorCode');
    },
    setErrorCode: function setErrorCode(errorCode) {
      this.set('errorCode', errorCode);
    },
    getOwner: function getOwner() {
      return this.get('owner');
    },
    setOwner: function setOwner(owner) {
      this.set('owner', owner);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
      this.setIsApproved(null);
      this.setIsPlanned(null);
      this.setErrorCode(null);
      this.setDeviationType(null);
      this.setOwner(null);
    }
  });

  _exports.default = _default;
});
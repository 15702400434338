define("isolocity/services/search/complaints", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    isStatus: null,
    department: null,
    owner: null,
    getIsStatus: function getIsStatus() {
      return this.get('isStatus');
    },
    setIsStatus: function setIsStatus(isStatus) {
      this.set('isStatus', isStatus);
    },
    getDepartment: function getDepartment() {
      return this.get('department');
    },
    setDepartment: function setDepartment(department) {
      this.set('department', department);
    },
    getOwner: function getOwner() {
      return this.get('owner');
    },
    setOwner: function setOwner(owner) {
      this.set('owner', owner);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
      this.setIsStatus(null);
      this.setDepartment(null);
      this.setOwner(null);
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/corrective-actions/edit/controller", ["exports", "isolocity/config/environment", "isolocity/mixins/file-download-mixin", "isolocity/mixins/pdf-generation-mixin", "isolocity/mixins/has-departments-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/creates-action-items-mixin", "isolocity/mixins/has-action-items-mixin", "isolocity/mixins/creates-notes-mixin", "isolocity/mixins/has-notes-mixin", "isolocity/mixins/unsaved-changes-handler-mixin", "isolocity/mixins/owners-and-subscribers-mixin", "isolocity/mixins/corrective-action-mixin", "isolocity/mixins/car-preventative-action-mixin", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/icon-list-mixin", "isolocity/mixins/module-required-fields-mixin", "isolocity/mixins/activity-log-mixin"], function (_exports, _environment, _fileDownloadMixin, _pdfGenerationMixin, _hasDepartmentsMixin, _requiredFieldsMixin, _historyHandlerMixin, _uploadFilesMixin, _userOrganizationMixin, _createsActionItemsMixin, _hasActionItemsMixin, _createsNotesMixin, _hasNotesMixin, _unsavedChangesHandlerMixin, _ownersAndSubscribersMixin, _correctiveActionMixin, _carPreventativeActionMixin, _ajaxRequestMixin, _eSignatureMixin, _iconListMixin, _moduleRequiredFieldsMixin, _activityLogMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Controller.extend(_fileDownloadMixin.default, _activityLogMixin.default, _requiredFieldsMixin.default, _hasDepartmentsMixin.default, _historyHandlerMixin.default, _pdfGenerationMixin.default, _uploadFilesMixin.default, _userOrganizationMixin.default, _createsActionItemsMixin.default, _hasActionItemsMixin.default, _createsNotesMixin.default, _hasNotesMixin.default, _unsavedChangesHandlerMixin.default, _ownersAndSubscribersMixin.default, _correctiveActionMixin.default, _carPreventativeActionMixin.default, _ajaxRequestMixin.default, _eSignatureMixin.default, _iconListMixin.default, _moduleRequiredFieldsMixin.default, {
    PdfTitle: 'Corrective Action Report',
    isSaving: false,
    isResendingSupplierEmail: false,
    isCreatingReport: false,
    isControlSystemSaving: false,
    isEdit: true,
    currentSupplierId: false,
    isAddingOwnerApproval: false,
    isAddingInspectorApproval: false,
    isRejectingByOwner: false,
    isRejectingByInspector: false,
    noteEntityType: 'car',
    actionLink: 'corrective-actions.edit',
    actionEntityType: 'car',
    intl: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    store: Ember.inject.service(),
    persistence: Ember.inject.service(),
    modules: Ember.inject.service('modules'),
    selectedControlSystemUpdate: null,
    sidebarCurrentTab: 'action-items',
    sidebarNote: '',
    moduleSlug: 'corrective-actions',
    defaultRequiredFields: ['po_number', 'date_opened'],
    defaultRequiredFieldsTranslation: {
      'po_number': 'Job #',
      'date_opened': 'Date Opened',
      'description': 'Description',
      'immediate_action': 'Immediate Action',
      "verification_completed_description": "Verification Description",
      'root_cause_description': 'Root Cause Description',
      'approval_completed_description': 'Conclusion',
      'regulatory_authorization_description': 'Supplier\'s Verification Actions',
      'process_effect': 'Effect on Other Processes',
      'preventative_action_description': 'Preventative Description'
    },
    relatedRequiredFields: ['preventative_action_description', 'approval_comlpeted_description'],
    actionTitleOptions: ['Complete Description', 'Complete Root Cause', 'Complete Preventative Action', 'Complete Verification Action', 'Complete and close out report'],
    controlActionOptions: [{
      code: 'car_ctrl_sop_updates',
      name: 'SOP Updates'
    }, {
      code: 'car_ctrl_pfmea_updates',
      name: 'PFMEA Updates'
    }, {
      code: 'car_ctrl_plan_updates',
      name: 'Control Plan Updates'
    }, {
      code: 'car_ctrl_similar_process_review',
      name: 'Similar Process Review'
    }, {
      code: 'car_ctrl_training_records_updates',
      name: 'Training Records Updates'
    }],
    complaintModuleName: Ember.computed('modules', function () {
      var complaintModule = this.get('modules').getModuleBySlug('complaints');
      return complaintModule.name;
    }),
    ncrModuleName: Ember.computed('modules', function () {
      var ncrModule = this.get('modules').getModuleBySlug('ncrs');
      return ncrModule.name;
    }),
    deviationModuleName: Ember.computed('modules', function () {
      var deviationModule = this.get('modules').getModuleBySlug('deviations');
      return deviationModule.name;
    }),
    ccrModuleName: Ember.computed('modules', function () {
      var ccrModule = this.get('modules').getModuleBySlug('ccrs');
      return ccrModule.name;
    }),
    auditReportModuleName: Ember.computed('modules', function () {
      var auditReportModule = this.get('modules').getModuleBySlug('audit-reports');
      return auditReportModule.name;
    }),
    inspectionModuleName: Ember.computed('modules', function () {
      var inspectionModule = this.get('modules').getModuleBySlug('inspections');
      return inspectionModule.name;
    }),
    oosModuleName: Ember.computed('modules', function () {
      var oosModule = this.get('modules').getModuleBySlug('oosforms');
      return oosModule.name;
    }),
    equipmentInspectionModuleName: Ember.computed('modules', function () {
      var equipmentInspectionModule = this.get('modules').getModuleBySlug('inspections.equipment');
      return equipmentInspectionModule.name;
    }),
    isSourceSectionLocked: Ember.computed('isOwnedByCurrentUser', 'model.type_part_id', 'model.isDispositionAuthorized', 'model.approval_completed_at', 'model.preventative_actions', 'model.verification_completed_at', function () {
      if (this.get('model.type_part_id') && this.get('model.isDispositionAuthorized')) {
        return true;
      } else if (this.get('model.approval_completed_at')) {
        return true;
      } else if (this.get('model.approval_completed_at')) {
        this.get('model.preventative_actions').forEach(function (preventative) {
          if (preventative.get('authorized_at')) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.get('model.verification_completed_at')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isDescriptionPartLocked: Ember.computed('isOwnedByCurrentUser', 'model.approval_completed_at', 'model.preventative_actions', 'model.verification_completed_at', function () {
      if (this.get('model.approval_completed_at')) {
        return true;
      } else if (this.get('model.approval_completed_at')) {
        this.get('model.preventative_actions').forEach(function (preventative) {
          if (preventative.get('authorized_at')) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.get('model.verification_completed_at')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isPreventativeActionsLocked: Ember.computed('isOwnedByCurrentUser', 'model.preventative_actions', function () {
      this.get('model.preventative_actions').forEach(function (preventative) {
        if (preventative.get('authorized_at')) {
          return true;
        } else {
          return false;
        }
      });

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    isReadOnly: Ember.computed('isOwnedByCurrentUser', 'model.is_closed', function () {
      if (this.get('model.is_closed') || !this.get('permissions').hasRoleAtLeast('production')) {
        return true;
      }

      if (this.get('isOwnedByCurrentUser') || this.get('permissions').hasRoleAtLeast('admin')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('production');
    }),
    controlActionItem: Ember.computed('model.all_action_items', 'model.allUsers', function () {
      return this.store.createRecord('action-item', {
        users: this.get('model.allUsers')
      });
    }),
    isFiveWhyAnalysis: Ember.computed('model.root_cause_why_1', 'model.root_cause_why_2', 'model.root_cause_why_3', 'model.root_cause_why_4', 'model.root_cause_why_5', function () {
      return !Ember.isBlank(this.get('model.root_cause_why_1')) || !Ember.isBlank(this.get('model.root_cause_why_2')) || !Ember.isBlank(this.get('model.root_cause_why_3')) || !Ember.isBlank(this.get('model.root_cause_why_4')) || !Ember.isBlank(this.get('model.root_cause_why_5'));
    }),
    isFishboneAnalysis: Ember.computed('model.root_cause_fishbone_measurement', 'model.root_cause_fishbone_material', 'model.root_cause_fishbone_machine', 'model.root_cause_fishbone_mother_nature', 'model.root_cause_fishbone_man_power', 'model.root_cause_fishbone_method', function () {
      return !Ember.isBlank(this.get('model.root_cause_fishbone_measurement')) || !Ember.isBlank(this.get('model.root_cause_fishbone_material')) || !Ember.isBlank(this.get('model.root_cause_fishbone_machine')) || !Ember.isBlank(this.get('model.root_cause_fishbone_mother_nature')) || !Ember.isBlank(this.get('model.root_cause_fishbone_man_power')) || !Ember.isBlank(this.get('model.root_cause_fishbone_method'));
    }),
    canDeleteCar: Ember.computed('isReadOnly', function () {
      return this.get('permissions').hasRoleAtLeast('admin') && !this.get('isReadOnly');
    }),
    canReopenCar: Ember.computed(function () {
      if (this.get('permissions').hasRoleAtLeast('manager') && this.get('authManager.session.currentUser.can_reopen_reports')) {
        return true;
      }

      return this.get('permissions').hasRoleAtLeast('admin');
    }),
    canUpgradeCarsToCcrs: Ember.computed('model.ccr_created.id', function () {
      if (!this.get('permissions').hasRoleAtLeast('production')) {
        return false;
      }

      return this.get('permissions').hasRoleAtLeast('admin') || this.get('isOwnedByCurrentUser');
    }),
    hasFilesUploader: Ember.computed('model.is_closed', 'model.isSaving', function () {
      var _isClosed = this.get('model.is_closed'),
          _isSaving = this.get('model.isSaving');

      return !_isClosed || _isSaving;
    }),
    hasSource: Ember.computed('model.car_source_id', function () {
      var source = this.get('model.car_source_id.name');
      return source === 'Customer' || source === 'Supplier';
    }),
    hasSupplierChanged: Ember.computed('model.source_supplier_id', function () {
      var model = this.get('model');
      return model.get('source_supplier_id.id') !== model.get('original_source_supplier_id');
    }),
    onSourceChange: Ember.observer('model.car_source_id', function () {
      var model = this.get('model');

      if (model.get('car_source_id.name') !== 'Customer') {
        model.set('source_customer_id', null);
      }

      if (model.get('car_source_id.name') !== 'Supplier') {
        model.set('source_supplier_id', null);
      }
    }),
    onTypeChange: Ember.observer('model.car_type_id.id', function () {
      var model = this.get('model');

      if (Ember.isEmpty(model) || model.get('isDeleted') || model.get('isDeleting') || Ember.isEmpty(model.get('car_type_id.code'))) {
        return;
      }

      if (model.get('car_type_id.code') !== 'INS') {
        model.set('type_inspection_id', null);
      }

      if (model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_id', null);
      }

      if (model.get('car_type_id.code') !== 'INS' && model.get('car_type_id.code') !== 'PRT') {
        model.set('type_part_rev_number', null);
        model.set('disposition', null);
        model.set('disposition_authorized_by', null);
      }
    }),

    get hasValidPreventativeAction() {
      if (Ember.isEmpty(this.get('model.preventative_actions'))) {
        return false;
      }

      var isValid = true;
      var preventativeActions = this.get('model.preventative_actions').toArray();

      var _iterator = _createForOfIteratorHelper(preventativeActions),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var preventative = _step.value;

          if (Ember.isEmpty(preventative.get('description'))) {
            isValid = false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return isValid;
    },

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('corrective-actions', 'is_reason_required_on_save', false);
    },

    get isDifferentApproversRequired() {
      return this.get('modules').getModuleSetting('corrective-actions', 'is_require_different_approvers', false);
    },

    modelSettings: null,
    onModelSettingsChange: Ember.observer('model.custom_form', function () {
      var model = this.get('model');
      var newSettings = !Ember.isBlank(model.get('custom_form')) ? JSON.parse(model.get('custom_form')) : JSON.parse('{ "settings": { "fields":{}, "sections":{} } }'); // Initiate settings key if it is missing in custom_form array

      if (!Ember.isBlank(newSettings) && Ember.isBlank(newSettings['settings'])) {
        newSettings['settings'] = JSON.parse('{ "fields":{}, "sections":{} }');
      } // Get settings from default module settings and update if applicable


      var oldSettings = this.get('module.settings');

      for (var key in oldSettings) {
        // In modules.settings property for this report, fields are under extra level called 'visible' or 'required'
        if (key == 'fields') {
          if (oldSettings['fields'].hasOwnProperty('visible')) {
            for (var childKey in oldSettings['fields']['visible']) {
              if (Ember.isBlank(newSettings['settings']['fields'][childKey])) {
                // Only update if setting does not exist on current report
                newSettings['settings']['fields'][childKey] = oldSettings['fields']['visible'][childKey];
              }
            }
          } else {
            for (var childKey in oldSettings['fields']['required']) {
              if (Ember.isBlank(newSettings['settings']['fields'][childKey])) {
                // Only update if setting does not exist on current report
                newSettings['settings']['fields'][childKey] = oldSettings['fields']['required'][childKey];
              }
            }
          }
        }

        if (key == 'sections') {
          for (var childKey in oldSettings['sections']) {
            if (Ember.isBlank(newSettings['settings']['sections'][childKey])) {
              // Only update if setting does not exist on current report
              newSettings['settings']['sections'][childKey] = oldSettings['sections'][childKey];
            }
          }
        } // For all other cases, fields are under current level


        if (!['fields', 'sections', 'required', 'helpers', 'configuration'].includes(key)) {
          if (Ember.isBlank(newSettings['settings']['fields'][key])) {
            // Only update if setting does not exist on current report
            newSettings['settings']['fields'][key] = oldSettings[key];
          }
        }
      }

      this.set('modelSettings', newSettings);
    }),
    actions: {
      save: function save() {
        var isClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var fm = this.get('flashMessages');

        if (isClose) {
          var required = this.get('requiredFields');

          if (required.indexOf('preventative_action_description') !== -1 && !this.get('hasValidPreventativeAction')) {
            fm.danger('Please include a description of the preventative action(s)');
            return;
          }
        }

        if (!this.get('isValid') && isClose) {
          this.send('showErrors');
          return;
        }

        if (Ember.isBlank(this.get('model.owners'))) {
          fm.danger(this.get('intl').t('At least one owner is required'));
          return;
        }

        var model = this.get('model');

        if (model.get('source_supplier_id')) {
          model.set('original_source_supplier_id', model.get('source_supplier_id.id'));
        }

        this.send('saveWithEsignature', model, isClose);
      },
      saveWithEsignature: function saveWithEsignature(model) {
        var _this2 = this;

        var isClose = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.set('isSaving', true);

        if (isClose) {
          // Check if 2 approvals are required
          if (this.get('module.settings.sections.owner_and_quality_inspector_approval')) {
            // Check 2 approvals meet minimum requirements for closing report
            if (!Ember.isBlank(model.get('owner_rejected_at')) || !Ember.isBlank(model.get('inspector_rejected_at')) || !Ember.isBlank(model.get('owner_approved_at')) && !Ember.isBlank(model.get('inspector_approved_at'))) {
              model.set('date_closed', new moment());
            } else {
              var fm = this.get('flashMessages');
              fm.danger('Owner must approve or reject the report in order to be closed');
            }
          } else {
            model.set('date_closed', new moment());
          }
        }

        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (car) {
          if (model.get('preventative_actions.length') > 0) {
            _this2.send('savePreventativeAndAttachments', car, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
          } else {
            _this2.send('saveAttachments', car, false, _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));
          }

          _this2.send('saveBatchRecords', _this2.get('eSignaturePassword'), _this2.get('eSignatureReason'));

          _this2.notifyPropertyChange('hasSupplierChanged');
        }, function (error) {
          _this2.set('isSaving', false);

          var fm = _this2.get('flashMessages');

          fm.danger(error.errors);
        });
      },
      reopen: function reopen() {
        var _this3 = this;

        var model = this.get('model');
        var fm = this.get('flashMessages');
        var authHeaders = this.get('authHeaders');
        this.set('isReopening', true);
        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (car) {
          // Reset owners approvals
          $.ajax({
            headers: authHeaders,
            url: _this3.getApiUrl('/car/' + model.get('id') + '/reopen'),
            method: 'POST',
            data: {
              password: _this3.get('eSignaturePassword'),
              esignature_reason: _this3.get('eSignatureReason')
            }
          }).then(function () {
            model.reload().then(function () {
              _this3.send('clearEsignature');

              fm.success(_this3.get('intl').t('The report has been re-opened'));
            });
          }).fail(function () {
            fm.danger(_this3.get('intl').t('An error occurred while trying to re-open the report'));
          }).always(function () {
            _this3.set('isReopening', false);
          });
        }, function (error) {
          fm.danger('There was an error re-opening the report');
        });
      },
      authorizeDisposition: function authorizeDisposition() {
        var _this4 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this4.send('doAuthorizeDisposition');

          _this4.set('dispositionAuthorizePromise', null);
        }, function () {
          _this4.set('dispositionAuthorizePromise', null);
        });
        this.set('dispositionAuthorizePromise', defer);
      },
      approveVerification: function approveVerification() {
        var _this5 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this5.send('doVerificationApproval');

          _this5.set('verificationApprovalPromise', null);
        }, function () {
          _this5.set('verificationApprovalPromise', null);
        });
        this.set('verificationApprovalPromise', defer);
      },
      approveApproval: function approveApproval() {
        var _this6 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this6.send('doApprovalApproval');

          _this6.set('approvalApprovalPromise', null);
        }, function () {
          _this6.set('approvalApprovalPromise', null);
        });
        this.set('approvalApprovalPromise', defer);
      },
      authorizePreventative: function authorizePreventative(preventative) {
        var _this7 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this7.send('doAuthorizePreventative', preventative);

          _this7.set('preventativeAuthorizePromise', null);
        }, function () {
          _this7.set('preventativeAuthorizePromise', null);
        });
        this.set('preventativeAuthorizePromise', defer);
      },
      doAuthorizeDisposition: function doAuthorizeDisposition() {
        var _this8 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isDispositionAuthorizing', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/disposition/authorize'),
          data: {
            password: this.get('eSignaturePassword')
          },
          method: 'POST'
        }).then(function (data) {
          car.saveWithEsignature(_this8.get('eSignaturePassword'), _this8.get('eSignatureReason')).then(function () {
            car.reload();

            _this8.send('saveAttachments', car);
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize the disposition');
        }).always(function () {
          _this8.set('isDispositionAuthorizing', false);

          _this8.set('isSaving', false);

          _this8.set('eSignaturePassword', '');
        });
      },
      doVerificationApproval: function doVerificationApproval() {
        var _this9 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isVerificationCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/verification/approve'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function () {
          car.saveWithEsignature(_this9.get('eSignaturePassword'), _this9.get('eSignatureReason')).then(function () {
            _this9.get('model').reload().then(function () {
              _this9.send('saveAttachments', car);

              fm.success('Your verification approval has been added to this report');
            });
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize the verification');
        }).always(function () {
          _this9.set('isVerificationCompleting', false);

          _this9.set('isSaving', false);

          _this9.set('eSignaturePassword', '');
        });
      },
      undoVerificationApproval: function undoVerificationApproval() {
        var _this10 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isVerificationCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/verification/approve'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function () {
          car.saveWithEsignature(_this10.get('eSignaturePassword'), _this10.get('eSignatureReason')).then(function () {
            _this10.get('model').reload().then(function () {
              _this10.send('saveAttachments', car);

              fm.success('Your verification approval has been removed for this report.');
            });
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger('You are not authorized to perform this action');
          }
        }).always(function () {
          _this10.set('isVerificationCompleting', false);

          _this10.set('isSaving', false);

          _this10.set('eSignaturePassword', '');
        });
      },
      doApprovalApproval: function doApprovalApproval() {
        var _this11 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        this.set('isApprovalCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/approval/approve'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          car.saveWithEsignature(_this11.get('eSignaturePassword'), _this11.get('eSignatureReason')).then(function () {
            car.reload();

            _this11.send('saveAttachments', car);
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize the verification');
        }).always(function () {
          _this11.set('isApprovalCompleting', false);

          _this11.set('isSaving', false);

          _this11.set('eSignaturePassword', '');
        });
      },
      upgradeToChangeControl: function upgradeToChangeControl() {
        var model = this.get('model');
        var route = 'ccrs.new';
        this.get('history').setRoute('corrective-actions');
        var ccr = this.store.createRecord('ccr', {
          causer_id: model.get('id'),
          causer_type: 'car',
          source: 'Corrective Actions',
          isSourceReadOnly: true
        });
        this.get('persistence').add({
          route: route,
          model: ccr
        });
        this.transitionToRoute(route);
        this.get('flashMessages').clearMessages();
      },
      doAuthorizePreventative: function doAuthorizePreventative(preventative) {
        var _this12 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/preventative/' + preventative.get('id') + '/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          preventative.reload();

          _this12.send('saveAttachments', car);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize this action');
        }).always(function () {
          _this12.set('isSaving', false);

          _this12.set('eSignaturePassword', '');
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(car) {
        this.set('isSaving', false);
        var fm = this.get('flashMessages');
        fm.success(this.get('intl').t('corrective-actions.other.The changes to this action report have been saved successfully'));
      },
      handleSelection: function handleSelection() {
        var _this13 = this;

        var closeDate = this.get('model.date_closed');
        this.set('promise', null);

        if (!Ember.isBlank(closeDate) && closeDate.isValid()) {
          var defer = Ember.RSVP.defer();
          defer.promise.then(function () {
            _this13.set('promise', null);
          }, function () {
            _this13.notifyPropertyChange('requiredFields');

            _this13.set('model.date_closed', null);

            _this13.set('promise', null);
          });
          this.set('promise', defer);
        }
      },
      remove: function remove() {
        var _this14 = this;

        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this14.get('model').destroyRecord().then(function () {
            _this14.transitionToRoute('corrective-actions');
          });

          _this14.set('deletePromise', null);
        }, function () {
          _this14.set('deletePromise', null);
        });
        this.set('deletePromise', defer);
      },
      createReport: function createReport() {
        var car = this.get('model');
        var report = {};
        this.set('isCreatingReport', true);
        var reportContent = this.getCarReportContent(car);
        this.send('generatePDF', reportContent);
        this.set('isCreatingReport', false);
      },
      createReportNew: function createReportNew() {
        var _this15 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var car = this.get('model'),
            fm = this.get('flashMessages'),
            reportContent = [];
        this.set('isCreatingReport', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/car/' + car.get('id') + '/pdf'),
          method: 'GET'
        }).done(function (data) {
          if (isDownload) {
            var id = _this15.get('model.id');

            var model = 'car';

            var carNumber = _this15.get('model.car_number');

            var description = "Corrective Action Report #".concat(carNumber, " has been downloaded");

            _this15.send('newActivityLog', id, model, description);

            _this15.send('downloadPdfFile', data, 'CAR_' + _this15.get('model.car_number'));
          } else {
            _this15.send('openPdfInTab', data);
          }
        }).fail(function (data) {
          fm.danger(_this15.get('intl').t('An error occurred while trying to generate the report'));
        }).always(function (data) {
          _this15.set('isCreatingReport', false);
        });
      },
      createReportNewWithAttachments: function createReportNewWithAttachments() {
        var _this16 = this;

        var isDownload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.send('createReportNew', isDownload);
        var car = this.get('model');
        var files = car.get('files');
        files.forEach(function (file, i) {
          // Delay download between files for browser optimization
          var timeout = 500 * i;
          setTimeout(function () {
            _this16.send('downloadFile', file);
          }, timeout);
        });
      },
      resendSupplierCarEmail: function resendSupplierCarEmail() {
        var fm = this.get('flashMessages');

        var authToken = this.get('authManager.session.content.authenticated.access_token'),
            model = this.get('model'),
            _this = this;

        this.set('isResendingSupplierEmail', true);
        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/car/resend-supplier-email?token=' + authToken,
          data: {
            car_id: model.get('id')
          },
          method: 'POST',
          success: function success(data) {
            _this.set('isResendingSupplierEmail', false);

            fm.success(_this.get('intl').t("corrective-actions.other.The email has been re-sent to the supplier"));
          }
        });
      },
      toggleFiveWhyAnalysis: function toggleFiveWhyAnalysis() {
        this.set('isFiveWhyAnalysis', true);
      },
      toggleFishboneAnalysis: function toggleFishboneAnalysis() {
        this.set('isFishboneAnalysis', true);
      },
      selectControlSystemUpdate: function selectControlSystemUpdate(item) {
        this.set('controlActionItem.code', item.code);
        this.set('controlActionItem.title', item.name);
      },
      createControlSystemAction: function createControlSystemAction() {
        var _this17 = this;

        var action = this.get('controlActionItem'),
            fm = this.get('flashMessages');

        if (Ember.isEmpty(action.get('assignee.id')) || Ember.isEmpty(action.get('code'))) {
          fm.danger('Please select a control system update and responsible user');
          return;
        }

        action.set('entity_type', 'car');
        action.set('entity_id', this.get('model.id'));
        action.set('use_queue', true);
        action.set('assignee_id', action.get('assignee.id'));
        action.set('priority', 'Medium');
        action.set('due_date', new moment().add(5, 'days'));
        action.set('link', 'corrective-actions.edit');
        this.set('isControlSystemSaving', true);
        action.save().then(function () {
          fm.success('A new control system update has been added to this report');

          _this17.set('isControlSystemSaving', false);

          _this17.set('selectedControlSystemUpdate', null);

          _this17.notifyPropertyChange('model.control_system_action_items');

          _this17.set('controlActionItem', _this17.store.createRecord('action-item', {
            users: _this17.store.peekAll('user')
          }));

          _this17.get('model').reload();
        });
      },
      approve: function approve(type) {
        var _this18 = this;

        var fm = this.get('flashMessages');
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this18.send('doApproval', type);

          _this18.set('approvalApprovalPromise', null);
        }, function () {
          _this18.set('approvalApprovalPromise', null);
        });
        this.set('approvalApprovalPromise', defer);
      },
      doApproval: function doApproval(type) {
        var _this19 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var approvedReason = '';

        if (type === 'owner') {
          this.set('isAddingOwnerApproval', true);
          approvedReason = this.get('model.owner_approved_reason');
        } else if (type === 'inspector') {
          this.set('isAddingInspectorApproval', true);
          approvedReason = this.get('model.inspector_approved_reason');
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/car/' + this.get('model.id') + '/approve',
          data: {
            user_type: type,
            approved_reason: approvedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: approvedReason
          },
          method: 'POST'
        }).then(function () {
          _this19.get('model').saveWithEsignature(_this19.get('eSignaturePassword'), _this19.get('eSignatureReason')).then(function () {
            _this19.get('model').reload().then(function () {
              fm.success(intl.t('Your approval has been added to this report'));
            });
          });
        }).fail(function () {
          fm.danger(intl.t('Please rectify:'));
          fm.danger(intl.t('- Your password is correct and all required fields are complete'));

          if (_this19.get('isDifferentApproversRequired')) {
            fm.danger(intl.t('- Owner and inspector approvers are different'));
          }
        }).always(function () {
          _this19.set('isAddingOwnerApproval', false);

          _this19.set('isAddingInspectorApproval', false);

          _this19.set('eSignaturePassword', '');
        });
      },
      removeApproval: function removeApproval(type) {
        var _this20 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var approvedReason = '';

        if (type === 'owner') {
          this.set('isAddingOwnerApproval', true);
          approvedReason = this.get('model.owner_approved_reason');
        } else if (type === 'inspector') {
          this.set('isAddingInspectorApproval', true);
          approvedReason = this.get('model.inspector_approved_reason');
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/car/' + this.get('model.id') + '/approve',
          data: {
            user_type: type,
            approved_reason: approvedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: approvedReason
          },
          method: 'DELETE'
        }).then(function () {
          _this20.get('model').reload().then(function () {
            fm.success(intl.t('Your approval has been removed for this report.'));
          });
        }).fail(function (err) {
          if (err.responseJSON && !Ember.isEmpty(err.responseJSON.message)) {
            fm.danger(err.responseJSON.message);
          } else {
            fm.danger('You are not authorized to perform this action');
          }
        }).always(function () {
          _this20.set('isAddingOwnerApproval', false);

          _this20.set('isAddingInspectorApproval', false);

          _this20.set('eSignaturePassword', '');
        });
      },
      reject: function reject(type) {
        var _this21 = this;

        var fm = this.get('flashMessages'),
            intl = this.get('intl');
        var authToken = this.get('authManager.session.content.authenticated.access_token');
        var rejectedReason = '';

        if (type === 'owner') {
          this.set('isRejectingByOwner', true);
          rejectedReason = this.get('model.owner_rejected_reason');
        } else if (type === 'inspector') {
          this.set('isRejectingByInspector', true);
          rejectedReason = this.get('model.inspector_rejected_reason');
        }

        if (Ember.isBlank(rejectedReason) || !rejectedReason) {
          this.set('isRejectingByOwner', false);
          this.set('isRejectingByInspector', false);
          fm.danger(intl.t('A rejection reason is required in order to proceed'));
          return;
        }

        $.ajax({
          headers: this.get('authHeaders'),
          url: _environment.default.APP.host + '/' + _environment.default.APP.namespace + '/car/' + this.get('model.id') + '/reject',
          data: {
            user_type: type,
            rejected_reason: rejectedReason,
            password: this.get('eSignaturePassword'),
            esignature_reason: rejectedReason
          },
          method: 'POST'
        }).then(function () {
          _this21.get('model').reload().then(function () {
            fm.success(intl.t('You have successfully rejected this report'));
          });
        }).fail(function () {
          fm.danger(intl.t('An error occurred while trying to reject the report, please ensure your password is correct'));
        }).always(function () {
          _this21.set('isRejectingByOwner', false);

          _this21.set('isRejectingByInspector', false);

          _this21.set('eSignaturePassword', '');
        });
      },
      undoapproveApproval: function undoapproveApproval() {
        var _this22 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        this.set('isApprovalCompleting', true);
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/approval/approve'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function (data) {
          _this22.get('model').reload().then(function () {
            fm.success(intl.t('Your approval has been removed for this report.'));
          });
        }).fail(function (data) {
          fm.danger('An error occurred while trying to unauthorize the approval');
        }).always(function () {
          _this22.set('isApprovalCompleting', false);

          _this22.set('isSaving', false);

          _this22.set('eSignaturePassword', '');
        });
      },
      unauthorizePreventative: function unauthorizePreventative(preventative) {
        var _this23 = this;

        var authHeaders = this.get('authHeaders'),
            car = this.get('model'),
            fm = this.get('flashMessages'),
            intl = this.get('intl');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/car/' + car.get('id') + '/preventative/' + preventative.get('id') + '/authorize'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'DELETE'
        }).then(function (data) {
          preventative.reload();

          _this23.send('saveAttachments', car);
        }).fail(function (data) {
          fm.danger('An error occurred while trying to authorize this action');
        }).always(function () {
          _this23.set('isSaving', false);

          _this23.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});
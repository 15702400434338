define("isolocity/pods/components/batch-records/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isReadOnly: false,
    isNeedDisposition: false,
    dispositionValues: ['', 'Use As Is', 'Sorting', 'Scrap', 'Rework', 'Return', 'Repair', 'Other'],
    actions: {
      addBatchRecord: function addBatchRecord() {
        this.sendAction('addBatchRecord');
      },
      removeBatchRecord: function removeBatchRecord(item) {
        this.sendAction('removeBatchRecord', item);
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/inspections/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin", "isolocity/mixins/floating-sidebar-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/downloadable-reports-mixin"], function (_exports, _dateSearchMixin, _paginationMixin, _floatingSidebarMixin, _historyHandlerMixin, _downloadableReportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _paginationMixin.default, _floatingSidebarMixin.default, _historyHandlerMixin.default, _downloadableReportsMixin.default, {
    id_prefix: 'index',
    sortedModel: Ember.computed.sort('model.inspections', 'sortProperties'),
    sortProperties: Ember.computed('sortBy', function () {
      if (!Ember.isEmpty(this.get('sortBy'))) {
        return [];
      }

      return ['has_incomplete_action_items:desc', 'has_unread_notes:desc'];
    }),
    search: Ember.inject.service('search.inspections'),
    searchType: '',
    status: '',
    sortBy: '',
    searchDepartment: '',
    searchTypes: ['Finished Product', 'Raw Material'],
    sortByItems: ['Recently Updated'],
    revisions: [],
    isSwitchRevision: false,
    selectedRevision: null,
    isFilteredResults: Ember.computed(function () {
      return this.get('searchType') || this.get('sortBy') || this.get('searchText') || this.get('searchDepartment') || this.get('searchStatus');
    }),
    searchTypeDescriptor: Ember.computed('searchType', 'searchDepartment', function () {
      var descriptor = this.get('searchType') ? this.bold(this.get('searchType').toLowerCase()) + ' inspections' : 'inspections';

      if (!Ember.isBlank(this.get('searchDepartment'))) {
        descriptor += ' in ' + this.get('searchDepartment.name');
      }

      return descriptor.htmlSafe();
    }),
    allReportData: Ember.computed('allStartDate', 'allEndDate', 'customCharacteristics', 'customPart', 'isSwitchRevision', 'selectedRevision', function () {
      var drawingId = null;

      if (this.get('isSwitchRevision')) {
        drawingId = this.get('selectedRevision.id');
      } else {
        drawingId = this.get('customCharacteristics.id');
      }

      return {
        start_date: this.get('allStartDate') ? moment(this.get('allStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('allEndDate') ? moment(this.get('allEndDate')).format("YYYY-MM-DD") : null,
        drawing_id: drawingId,
        part_id: this.get('customPart') ? this.get('customPart.id') : null
      };
    }),
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setType(this.get('searchType'));
        this.get('search').setSortBy(this.get('sortBy'));
        this.get('search').setStatus(this.get('searchStatus'));
        this.get('search').setDepartmentId(this.get('searchDepartment.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('searchType', '');
        this.set('sortBy', '');
        this.set('searchDepartment', '');
        this.set('searchStatus', '');
        this.get('search').setType('');
        this.get('search').setSortBy('');
        this.get('search').setDepartmentId('');
        this.get('search').setStatus('');
      },
      restoreInspection: function restoreInspection(inspection) {
        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/inspection/' + inspection.get('id') + '/restore'),
          method: 'POST',
          success: function success(data) {
            location.replace("/inspections/" + inspection.get('id'));
          }
        }).fail(function (data) {
          fm.danger('An error occurred while trying to restore the inspection');
        });
      },
      handleSelectedDrawing: function handleSelectedDrawing(drawing) {
        var _this = this;

        this.store.findRecord('drawing', drawing.id, {
          reload: true
        }).then(function (drawing) {
          _this.set('revisions', drawing.revisions);
        });
      },
      showRevision: function showRevision() {
        this.set('isSwitchRevision', true);
      },
      hideRevision: function hideRevision() {
        this.set('isSwitchRevision', false);
        this.set('selectedRevision', null);
        this.set('customCharacteristics', null);
        this.set('revisions', []);
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/orders/edit/controller", ["exports", "isolocity/mixins/orders-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin"], function (_exports, _ordersMixin, _eSignatureMixin, _requiredFieldsMixin, _historyHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _ordersMixin.default, _eSignatureMixin.default, {
    modules: Ember.inject.service('modules'),
    constants: Ember.inject.service('constants'),
    isEdit: true,
    permissions: Ember.inject.service('user-permissions'),
    onModelChange: Ember.observer('model', function () {
      this.set('isOpen', this.get('model.is_closed') !== true);
      this.set('isClosed', !this.get('isOpen'));
    }),
    canReopenOrder: Ember.computed(function () {
      return this.get('permissions').hasRoleAtLeast('admin');
    }),

    get isReasonRequired() {
      return this.get('modules').getModuleSetting('orders', 'is_reason_required_on_save', false);
    },

    actions: {
      save: function save() {
        var isClose = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          this.set('showSaveErrors', false);

          if (!this.hasValidPartLineItems()) {
            var partText = this.get('constants').getModuleText('parts', 'singular', 'Part', 'lower');
            fm.danger('Please ensure you have selected a valid ' + partText + ' and quantity for each line item');
            this.set('showSaveErrors', true);
            return;
          }

          var model = this.get('model');

          if (!/\d/.test(model.get('order_number'))) {
            fm.danger('Please ensure that the order number contains at least one number');
            return;
          }

          this.send('saveWithEsignatures', model, isClose);
        } else {
          this.send('showErrors');
        }
      },
      saveWithEsignatures: function saveWithEsignatures(model) {
        var _this = this;

        var isClose = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var fm = this.get('flashMessages');
        this.set('isSaving', true);
        model.saveWithEsignature(this.get('eSignaturePassword'), this.get('eSignatureReason')).then(function (order) {
          var items = model.get('items');
          items.forEach(function (item) {
            if (item.get('hasDirtyAttributes')) {
              item.save();
            }
          });

          _this.set('isSaving', false);

          _this.notifyPropertyChange('model');

          fm.success('The order has been saved successfully.');

          if (isClose || model.get('actual_ship_date')) {
            _this.send('close');
          }
        }, function (error) {
          _this.set('isSaving', false);

          fm.danger('There was an error saving the report');
        });
      },
      close: function close() {
        var _this2 = this;

        var model = this.get('model');
        var authHeaders = this.get('authHeaders'),
            fm = this.get('flashMessages');

        if (!model.get('actual_ship_date')) {
          fm.danger(this.get('intl').t('Please enter the actual ship date'));
          return;
        }

        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/orders/' + model.get('id') + '/close'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          model.set('is_closed', true);

          _this2.notifyPropertyChange('model');

          model.reload();
          fm.success('The order has been saved and closed successfully.');
        }).fail(function (data) {
          fm.danger(_this2.get('intl').t('An error occurred while trying to close the report'));
        }).always(function () {
          _this2.set('eSignaturePassword', '');
        });
      },
      reopen: function reopen() {
        var _this3 = this;

        var model = this.get('model');
        var authHeaders = this.get('authHeaders'),
            complaint = this.get('model'),
            fm = this.get('flashMessages');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/orders/' + complaint.get('id') + '/reopen'),
          data: {
            password: this.get('eSignaturePassword'),
            esignature_reason: this.get('eSignatureReason')
          },
          method: 'POST'
        }).then(function (data) {
          model.set('is_closed', false);

          _this3.notifyPropertyChange('model');

          model.reload();
          fm.success('The order has been re-opened.');
        }).fail(function (data) {
          fm.danger(_this3.get('intl').t('An error occurred while trying to re-open the report'));
        }).always(function () {
          _this3.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/deviations/index/route", ["exports", "isolocity/config/environment", "isolocity/mixins/paginated-route-mixin", "isolocity/mixins/authentication-routing-mixin", "isolocity/mixins/unsaved-changes-handler-mixin"], function (_exports, _environment, _paginatedRouteMixin, _authenticationRoutingMixin, _unsavedChangesHandlerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, _unsavedChangesHandlerMixin.default, _paginatedRouteMixin.default, {
    routeName: 'deviations.index',
    search: Ember.inject.service('search.deviations'),
    modelName: ['deviation'],
    model: function model(params) {
      this.startLoading();
      var fromDate = !Ember.isEmpty(this.get('search').getFromDate()) ? this.get('search').getFromDate() : null;
      var toDate = !Ember.isEmpty(this.get('search').getToDate()) ? this.get('search').getToDate() : null;
      var filters = this.getQueryFilters();
      filters['per_page'] = _environment.default.APP.pagination;
      filters['page'] = this.get('currentPage');
      filters['keyword'] = this.get('search').getKeyword();
      filters['is_approved'] = this.get('search').getIsApproved();
      filters['is_planned'] = this.get('search').getIsPlanned();
      filters['type'] = this.get('search').getDeviationType();
      filters['error_code'] = this.get('search').getErrorCode();
      filters['owner'] = this.get('search').getOwner();
      return {
        deviations: this.store.query('deviation', filters)
      };
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.deviations.then(function (deviations) {
        _this.doneLoading();

        _this.setPageMetadata(deviations);
      });
      model.errorCodes = this.store.findAll('error-code');
      model.parts = this.store.findAll('part');
      model.deviationTypes = this.store.findAll('deviation-type');
      model.allUsers = this.store.findAll('user');
    },
    actions: {
      doSearchModule: function doSearchModule() {
        this.set('currentPage', 1);
        this.refresh();
      },
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('deviations');
        }
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/equipment/archived/controller", ["exports", "isolocity/mixins/equipment-handler-mixin", "isolocity/mixins/date-search-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _equipmentHandlerMixin, _dateSearchMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_equipmentHandlerMixin.default, _dateSearchMixin.default, _paginationMixin.default, {
    sortProperties: ['is_active:desc', 'isOverdue:desc', 'is_critical:desc'],
    search: Ember.inject.service('search.equipments-archived'),
    permissions: Ember.inject.service('user-permissions'),
    selectedEquipment: null,
    equipmentType: null,
    isArchived: true,
    isOutForService: {
      id: false,
      name: 'In Service'
    },
    serviceOptions: [{
      id: false,
      name: 'In Service'
    }, {
      id: true,
      name: 'Out of Service'
    }],
    isActive: {
      id: true,
      name: 'Active'
    },
    statusOptions: [{
      id: true,
      name: 'Active'
    }, {
      id: false,
      name: 'Inactive'
    }],
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setIsActive(this.get('isActive.id'));
        this.get('search').setIsOutForService(this.get('isOutForService.id'));
        this.get('search').setEquipmentTypeId(this.get('equipmentType.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('isActive', {
          id: true,
          name: 'Active'
        });
        this.set('isOutForService', {
          id: false,
          name: 'In Service'
        });
        this.set('equipmentType', null);
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/azure/callback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0Ln7G/w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"welcome-container\"],[12],[2,\"\\n   \"],[1,[34,0]],[2,\"\\n   \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"\\n         \"],[6,[37,1],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"ISOlocity\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"h2\"],[12],[2,\"\\n         \"],[10,\"p\"],[12],[2,\"Loading...\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[13],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"\\n         \"],[10,\"p\"],[12],[6,[37,1],null,[[\"route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Back to login page\"]],\"parameters\":[]}]]],[13],[2,\"\\n      \"],[13],[2,\"\\n   \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"link-to\"]}",
    "meta": {
      "moduleName": "isolocity/pods/azure/callback/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/components/receiving-parts-table/row/component", ["exports", "isolocity/config/environment", "isolocity/mixins/table-editor-row-mixin", "isolocity/mixins/drawing-preview-mixin", "isolocity/mixins/units-of-measure-mixin", "isolocity/mixins/user-organization-mixin"], function (_exports, _environment, _tableEditorRowMixin, _drawingPreviewMixin, _unitsOfMeasureMixin, _userOrganizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableEditorRowMixin.default, _drawingPreviewMixin.default, _unitsOfMeasureMixin.default, _userOrganizationMixin.default, {
    config: _environment.default,
    tagName: 'tr',
    previewCharacteristicsCount: 2,
    isEdit: false,
    isNewBatchNumber: false,
    isNewSecondaryLotNumber: false,
    pCount: 0,
    popUpBatchCount: 0,
    hasPart: Ember.computed('model.part_id', function () {
      return !Ember.isEmpty(this.get('model.part_id.id'));
    }),
    partUnitsOfMeasure: Ember.computed('model.part_id.unit_type', function () {
      if (this.get('model.part_id.unit_type') === 'Mass' || this.get('model.part_id.unit_type') === 'Volume') {
        if (this.get('model.part_id.hasDensityAttributes')) {
          var massUnits = this.getUnitsOfMeasure('Mass');
          var volumeUnits = this.getUnitsOfMeasure('Volume');
          return massUnits.concat(volumeUnits);
        }
      }

      return this.getUnitsOfMeasure(this.get('model.part_id.unit_type'));
    }),
    onPartChanged: Ember.observer('model.part_id', function () {
      if (this.get('hasPart')) {
        Ember.run.once(this, function () {
          this.sendAction('onPartChange', this.get('model'));
        });
      }
    }),
    onRowChange: Ember.observer('isEdittable', 'model.part_id.id', 'model.quantity_accepted', 'model.quantity_rejected', 'model.unit_of_measure', 'model.expiry_date', 'model.part_id', function () {
      var _this = this;

      var model = this.get('model');
      var sortedItems = this.get('sortedItems');
      this.set('isValidRow', this.get('model.isValid'));

      if (this.get('isNewBatchNumber') == true) {
        sortedItems.forEach(function (element) {
          var serialNumber = model.serial_number;
          var hasDifferentSerialNumberTest = true;
          var popUpCount = 0;

          if (element.get('part_id.id') == model.get('part_id.id') && element.get('serial_number') == serialNumber) {
            hasDifferentSerialNumberTest = false;
          }

          if (hasDifferentSerialNumberTest == false && _this.get('pCount') == 0) {
            _this.set('confirmPromise', null);

            var defer = Ember.RSVP.defer();
            defer.promise.then(function () {
              _this.set('confirmPromise', null);
            }, function () {
              _this.set('confirmPromise', null);
            });

            _this.set('confirmPromise', defer);

            popUpCount++;

            _this.set('pCount', 1);

            hasDifferentSerialNumberTest = true;
          }
        });
      }

      if (this.get('isNewSecondaryLotNumber') == true) {
        sortedItems.forEach(function (element) {
          var secondaryLotNumber = model.secondary_lot_number;
          var hasDifferentSecondaryLotNumberTest = true;
          var popUpCount = 0;

          if (element.get('part_id.id') == model.get('part_id.id') && element.get('secondary_lot_number') == secondaryLotNumber) {
            hasDifferentSecondaryLotNumberTest = false;
          }

          if (hasDifferentSecondaryLotNumberTest == false && _this.get('pCount') == 0) {
            _this.set('confirmPromise', null);

            var defer = Ember.RSVP.defer();
            defer.promise.then(function () {
              _this.set('confirmPromise', null);
            }, function () {
              _this.set('confirmPromise', null);
            });

            _this.set('confirmPromise', defer);

            popUpCount++;

            _this.set('pCount', 1);

            hasDifferentSecondaryLotNumberTest = true;
          }
        });
      }
    }),
    onDisabledChange: Ember.observer('disabled', function () {
      if (this.get('disabled')) {
        this.set('model.is_active_batch', false);
      }
    }),
    onQuantityAcceptedChanged: Ember.observer('model.quantity_accepted', function () {
      if (!Ember.isBlank(this.get('model.part_id.is_fixed_cost')) && !Ember.isBlank(this.get('model.part_id.cost')) && this.get('model.part_id.cost_unit_of_measure') === this.get('model.unit_of_measure')) {
        var quantity = !Ember.isBlank(this.get('model.quantity_accepted')) ? this.get('model.quantity_accepted') : 0;
        var cost = quantity * this.get('model.part_id.cost');
        cost = cost.toFixed(2);
        this.set('model.cost', cost);
      }
    }),
    onUnitOfMeasureChanged: Ember.observer('model.unit_of_measure', function () {
      if (this.get('model.part_id.cost_unit_of_measure') !== this.get('model.unit_of_measure') && !Ember.isEmpty(this.get('model.unit_of_measure')) && !Ember.isEmpty(this.get('model.part_id.unit_of_measure'))) {
        this.set('model.cost', null);
      }
    }),
    actions: {
      togglePreview: function togglePreview() {
        var _this2 = this;

        this.set('model.edittable', false);
        var defer = Ember.RSVP.defer();
        defer.promise.then(function () {
          _this2.set('promise', null);

          _this2.sendAction('onDrawingRedirect', _this2.get('model.drawing_id'));
        }, function () {
          _this2.set('promise', null);
        });
        this.set('promise', defer);
      },
      toggleNewBatchNumber: function toggleNewBatchNumber() {
        this.set('isNewBatchNumber', !this.get('isNewBatchNumber'));
      },
      toggleNewSecondaryLotNumber: function toggleNewSecondaryLotNumber() {
        this.set('isNewSecondaryLotNumber', !this.get('isNewSecondaryLotNumber'));
      },
      refreshDrawing: function refreshDrawing() {
        this.sendAction('updateDrawing', this.get('model'));
      },
      notifySupplierPartsChange: function notifySupplierPartsChange() {
        this.sendAction('onSupplierPartsChange');
      },
      inspectItem: function inspectItem(item) {
        this.sendAction('inspectItem', item);
      },
      showCostInput: function showCostInput(item) {
        item.set('isShowCostInput', true);
      },
      checkAndChange: function checkAndChange() {
        var _this3 = this;

        var model = this.get('model');
        var fm = this.get('flashMessages');

        if (!model.serial_number || !model.expiry_date) {
          fm.danger('You have not entered an Expiry Date or Batch Number.');
          this.set('fieldCheckPromise', null);
          var defer = Ember.RSVP.defer();
          defer.promise.then(function () {
            _this3.set('fieldCheckPromise', null);

            _this3.actions.change.call(_this3);
          }, function () {
            _this3.set('fieldCheckPromise', null);
          });
          this.set('fieldCheckPromise', defer);
        } else {
          this.actions.change.call(this);
        }
      }
    }
  });

  _exports.default = _default;
});
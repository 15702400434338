define("isolocity/pods/components/nav-shared/component", ["exports", "isolocity/config/environment", "isolocity/mixins/logout-handler-mixin", "isolocity/mixins/user-organization-mixin", "isolocity/mixins/ajax-request-mixin"], function (_exports, _environment, _logoutHandlerMixin, _userOrganizationMixin, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_logoutHandlerMixin.default, _userOrganizationMixin.default, _ajaxRequestMixin.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service('router'),
    modulesService: Ember.inject.service('modules'),
    session: Ember.inject.service('session'),
    permissions: Ember.inject.service('user-permissions'),
    tagName: 'aside',
    version: null,
    classNameBindings: [':navigation', 'isCollapsed:collapsed'],
    sortProperties: ['order:asc'],
    sortedModules: Ember.computed.sort('modules', 'sortProperties'),

    get isAuthenticated() {
      return this.get('session.isAuthenticated');
    },

    modules: Ember.computed('isAuthenticated', function () {
      this.setModuleAlerts();
      return this.get('store').findAll('module');
    }),
    currentMenuSection: Ember.computed('currentModule', function () {
      if (!Ember.isBlank(this.get('currentModule')) && !this.get('currentModule.is_parent')) {
        return this.get('currentModule.parent_module_id.slug');
      }

      return null;
    }),
    currentModule: Ember.computed('modules', function () {
      var routeName = this.router.currentPath;
      routeName = routeName.replace('.index', '');
      routeName = routeName.replace('.new', '');
      routeName = routeName.replace('.edit', '');
      return this.get('modules').filterBy('ember_route', routeName).get('firstObject');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('version', _environment.default.APP.version); // collapse nav if we are on tablet

      if (window.innerWidth <= 1024) {
        this.set('isCollapsed', true);
      }

      this.setModuleAlerts();
    },
    setModuleAlerts: function setModuleAlerts() {
      var _this = this;

      if (!Ember.isBlank(this.get('moduleAlerts')) || !this.get('isAuthenticated')) {
        return;
      }

      $.ajax({
        headers: this.get('authHeaders'),
        url: this.getApiUrl('/me/alerts'),
        contentType: "application/json",
        method: 'GET'
      }).then(function (alerts) {
        _this.set('moduleAlerts', alerts);
      });
    },
    actions: {
      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('isCollapsed');
      },
      toggleMenuSection: function toggleMenuSection(section) {
        if (this.get('currentMenuSection') === section) {
          this.set('currentMenuSection', null);
        } else {
          this.set('currentMenuSection', section);
        }
      }
    }
  });

  _exports.default = _default;
});
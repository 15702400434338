define("isolocity/pods/customers/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/customer-form-mixin", "isolocity/mixins/owners-and-subscribers-mixin"], function (_exports, _uploadFilesMixin, _requiredFieldsMixin, _historyHandlerMixin, _customerFormMixin, _ownersAndSubscribersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _customerFormMixin.default, _ownersAndSubscribersMixin.default, {
    isLoading: false,
    id_prefix: 'new_',
    historyOverride: '',
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('customers');
    },

    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners')) && this.get('module.settings.is_authorized_users')) {
            fm.danger(this.get('intl').t('At least one authorized user is required'));
            return;
          }

          this.set('isLoading', true);
          var actionItems = this.get('model.all_action_items');
          this.get('model').save().then(function (customer) {
            _this.send('saveCustomer', customer, actionItems);
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      updateStatus: function updateStatus() {},
      afterAttachmentsSaved: function afterAttachmentsSaved(customer) {
        var fm = this.get('flashMessages');
        this.set('isLoading', false);
        this.get('history').setRoute('customers');
        this.transitionToRoute('customers.edit', customer);
        fm.success('You have successfully created a new customer.');
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/inspection/sample/result/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    is_passed: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    pass_fail: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    acceptable_unacceptable: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    yes_no: _emberData.default.attr('string', {
      allowNull: true
    }),
    value: _emberData.default.attr(),
    test_results: _emberData.default.attr(),
    is_optional: _emberData.default.attr('boolean'),
    is_approval_required: _emberData.default.attr('boolean'),
    drawing_characteristic_id: _emberData.default.belongsTo('drawing.characteristic'),
    drawing_category_id: _emberData.default.belongsTo('drawing.category'),
    inspection_sample_id: _emberData.default.belongsTo('inspection.sample'),
    approver_user_id: _emberData.default.belongsTo('user'),
    approved_at: _emberData.default.attr('utc'),
    secondary_approver_user_id: _emberData.default.belongsTo('user'),
    secondary_approved_at: _emberData.default.attr('utc'),
    //characteristic properties
    name: _emberData.default.attr('string'),
    operation: _emberData.default.attr('string'),
    dimensions: _emberData.default.attr(),
    tolerance: _emberData.default.attr(),
    inspection_method_id: _emberData.default.belongsTo('equipment'),
    equipment_id: _emberData.default.belongsTo('equipment'),
    //dimensions
    measurement: _emberData.default.attr(),
    unit: _emberData.default.attr('string'),
    //tolerance
    positive: _emberData.default.attr(),
    negative: _emberData.default.attr(),
    pass_or_fail: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_text_input: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    is_text_input_optional: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    acceptable_or_unacceptable: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    yes_or_no: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    radio_group: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    radio_group_choices: _emberData.default.hasMany('inspection.sample.result.radio_group_choice', {
      inverse: 'characteristic_id'
    }),
    radio_group_selected: _emberData.default.attr('number'),
    enforce_multiple_selection: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    // observers
    onPassableCriteriaChange: Ember.observer('pass_fail', 'acceptable_unacceptable', 'yes_no', 'value', 'radio_group_selected', function () {
      this.calculatePassFail();
    }),
    onIsPassedChange: Ember.observer('is_passed', function () {
      this.get('inspection_sample_id').then(function (sample) {
        if (!Ember.isEmpty(sample)) {
          sample.notifyPropertyChange('is_passed');
        }
      });
    }),
    // other
    isApproving: false,
    calculatePassFail: function calculatePassFail() {
      var value = parseFloat(this.get('value'));

      if (!Ember.isBlank(this.get('pass_fail'))) {
        this.set('is_passed', this.get('pass_fail') === true);
      } else if (this.get('acceptable_or_unacceptable') && (this.get('acceptable_unacceptable') === true || this.get('acceptable_unacceptable') === false)) {
        this.set('is_passed', true);
      } else if (this.get('yes_or_no') && (this.get('yes_no') === 'yes' || this.get('yes_no') === 'no' || this.get('yes_no') === 'na')) {
        this.set('is_passed', true);
      } else if (this.get('is_text_input_optional') && this.get('value')) {
        this.set('is_passed', true);
      } else if (!Ember.isBlank(value) && !isNaN(value)) {
        if (this.get('is_text_input')) return;
        if (this.get('is_text_input_optional')) return;
        var measurement = parseFloat(this.get('measurement')),
            max = parseFloat((measurement + parseFloat(this.get('positive'))).toPrecision(8)),
            min = parseFloat((measurement - parseFloat(this.get('negative'))).toPrecision(8));
        this.set('is_passed', value >= min && value <= max);
      } else if (this.get('radio_group') && !Ember.isEmpty(this.get('radio_group_selected'))) {
        var choiceRequired = false;
        var radio_group_choices = this.get('radio_group_choices');
        var results = {};
        var pass;
        radio_group_choices.forEach(function (choice, i) {
          if (choice.selected) {
            choiceRequired = true;
          }
        }); // Check if radio_group_selected is an array

        if (Array.isArray(this.get('radio_group_selected'))) {
          var radioGroupResults = this.get('radio_group_selected').map(function (index) {
            return radio_group_choices.objectAt(index);
          });
          pass = choiceRequired ? radioGroupResults.every(function (result) {
            return result.get('selected');
          }) : true;
        } else {
          var radioGroupResult = radio_group_choices.objectAt(this.get('radio_group_selected'));
          pass = choiceRequired ? radioGroupResult.get('selected') : true;
        }

        this.set('is_passed', pass);
        results['is_passed'] = pass;
        results['radio_group'] = true;
        results['radio_group_selected'] = this.get('radio_group_selected');
        this.set('test_results', JSON.stringify(results));
      } else {
        this.set('is_passed', null);
      }
    },
    approvedAt: Ember.computed('approved_at', function () {
      return Ember.isEmpty(this.get('approved_at')) ? '' : moment(this.get('approved_at')).format("MMM D, YYYY h:mm A");
    }),
    approvedUserName: Ember.computed('approver_user_id.name', function () {
      return Ember.isEmpty(this.get('approver_user_id.name')) ? '' : this.get('approver_user_id.name');
    }),
    secondaryApprovedAt: Ember.computed('secondary_approved_at', function () {
      return Ember.isEmpty(this.get('secondary_approved_at')) ? '' : moment(this.get('secondary_approved_at')).format("MMM D, YYYY h:mm A");
    }),
    secondaryApprovedUserName: Ember.computed('secondary_approver_user_id.name', function () {
      return Ember.isEmpty(this.get('secondary_approver_user_id.name')) ? '' : this.get('secondary_approver_user_id.name');
    })
  });

  _exports.default = _default;
});
define("isolocity/pods/drawings/archived/controller", ["exports", "isolocity/mixins/ajax-request-mixin", "isolocity/mixins/pagination-mixin"], function (_exports, _ajaxRequestMixin, _paginationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_ajaxRequestMixin.default, _paginationMixin.default, {
    search: Ember.inject.service('search.drawings-archived'),
    constants: Ember.inject.service('constants'),
    permissions: Ember.inject.service('user-permissions'),
    buttonTextSingular: Ember.computed(function () {
      return this.get('constants').getModuleText('drawings', 'singular', 'Drawing');
    }),
    searchText: '',
    bold: function bold(str) {
      return '<strong>' + str + '</strong>';
    },
    isFilteredResults: Ember.computed(function () {
      return this.get('searchText');
    }),
    searchDescriptor: Ember.computed('searchType', 'searchText', 'searchSupplier', 'searchDepartment', 'searchPartType', function () {
      var descriptor = '';

      if (this.get('searchText')) {
        descriptor += ' matching "' + this.bold(this.get('searchText')) + '"';
      }

      return descriptor.htmlSafe();
    }),
    actions: {
      handleSearchKeyPress: function handleSearchKeyPress(e, event) {
        if (!Ember.isBlank(e) && e.keyCode === 13) {
          this.send('searchDrawings');
        }
      },
      searchDrawings: function searchDrawings() {
        this.get('search').setKeyword(this.get('searchText'));
        this.send('doSearchDrawings');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.get('search').clear();
        this.send('doSearchDrawings');
        this.notifyPropertyChange('isFilteredResults');
        this.notifyPropertyChange('searchDescriptor');
      },
      unarchive: function unarchive(model) {
        var fm = this.get('flashMessages'),
            authHeaders = this.get('authHeaders');
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/drawing/' + model.get('id') + '/unarchive'),
          method: 'POST'
        }).fail(function (data) {});
      }
    }
  });

  _exports.default = _default;
});
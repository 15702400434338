define("isolocity/mixins/quantity-overview-mixin", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_ajaxRequestMixin.default, {
    quantityLists: null,
    currentPart: null,
    totalQuantity: 0,
    actions: {
      showQuantitListTable: function showQuantitListTable(part) {
        var fm = this.get('flashMessages'),
            _this = this;

        this.set('currentPart', part);
        $.ajax({
          headers: this.get('authHeaders'),
          url: this.getApiUrl('/inventory'),
          method: 'GET',
          data: {
            part_id: part.get('id'),
            quantity_overview: true
          }
        }).then(function (data) {
          _this.set('quantityLists', data.list);

          _this.set('totalQuantity', data.total);

          var defer = Ember.RSVP.defer();
          defer.promise.then(function (data) {
            _this.set('showQuantityModalPromise', null);
          }, function () {
            _this.set('showQuantityModalPromise', null);
          });

          _this.set('showQuantityModalPromise', defer);
        }).fail(function () {
          fm.danger('An error occurred while trying to load the quantity overview');
        });
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/module/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    parent_module_id: _emberData.default.belongsTo('module', {
      inverse: 'children'
    }),
    slug: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    singular: _emberData.default.attr('string'),
    plural: _emberData.default.attr('string'),
    ember_route: _emberData.default.attr('string'),
    ember_model: _emberData.default.attr('string'),
    morph_name: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    is_sidebar: _emberData.default.attr('boolean'),
    is_org_setting: _emberData.default.attr('boolean'),
    settings: _emberData.default.attr('object'),
    fields: _emberData.default.attr(),
    children: _emberData.default.hasMany('module', {
      inverse: 'parent_module_id'
    }),
    visible_min_role: _emberData.default.attr('string'),
    is_parent: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});
define("isolocity/services/search/ncrs", ["exports", "isolocity/mixins/search-service-mixin"], function (_exports, _searchServiceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_searchServiceMixin.default, {
    status: null,
    source: null,
    errorCode: null,
    owner: null,
    getStatus: function getStatus() {
      return this.get('status');
    },
    setStatus: function setStatus(status) {
      this.set('status', status);
    },
    getNcrSource: function getNcrSource() {
      return this.get('source');
    },
    setNcrSource: function setNcrSource(source) {
      this.set('source', source);
    },
    getErrorCode: function getErrorCode() {
      return this.get('errorCode');
    },
    setErrorCode: function setErrorCode(errorCode) {
      this.set('errorCode', errorCode);
    },
    getOwner: function getOwner() {
      return this.get('owner');
    },
    setOwner: function setOwner(owner) {
      this.set('owner', owner);
    },
    clear: function clear() {
      this.setKeyword('');
      this.setStatus(null);
      this.setNcrSource(null);
      this.setErrorCode(null);
      this.setOwner(null);
      this.setFromDate('');
      this.setToDate('');
      this.setDateRange('');
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/components/employees-table/component", ["exports", "isolocity/mixins/ajax-request-mixin"], function (_exports, _ajaxRequestMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_ajaxRequestMixin.default, {
    selectedEmployee: null,
    parentEntityName: 'list',
    amountToShow: 20,
    shownRecordsCountText: Ember.computed('amountToShow', 'model.{employees,employees.length}', function () {
      return this.get('amountToShow') + ' of ' + this.get('model.employees.length');
    }),
    hasMoreEmployees: Ember.computed('amountToShow', 'model.{employees,employees.length}', function () {
      return this.get('model.employees.length') > this.get('amountToShow');
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$(".data-table").tablesorter();
    },
    actions: {
      removeEmployeeFromGroup: function removeEmployeeFromGroup(employee) {
        // Remove employee from employee group
        if (this.get('parentEntityName') == 'group') {
          this.get('model.employees').removeObject(employee);

          if (this.get('isEdit')) {
            var enrolls = this.get('model.enrollments');

            if (enrolls !== undefined) {
              enrolls.forEach(function (enrollment) {
                if (enrollment.get('employee_id.id') == employee.get('id')) {
                  enrollment.destroyRecord();
                }
              });
            }
          }

          this.sendAction('removeItem');
        } // Remove employee from training schedule
        else if (this.get('parentEntityName') == 'training activity') {
            var fm = this.get('flashMessages');
            var authHeaders = this.get('authHeaders');

            var _this = this;

            $.ajax({
              headers: authHeaders,
              url: this.getApiUrl('/training-schedule-employees/remove-employee'),
              method: 'POST',
              data: {
                training_schedule_id: this.get('model.id'),
                employee_id: employee.get('id')
              }
            }).then(function () {
              _this.get('model.employees').removeObject(employee);

              fm.success('Employee has been removed from training successfully.');
            }).fail(function () {
              fm.danger('An error occurred while trying to remove employee from training schedule.');
            });
          }
      },
      showMoreEmployees: function showMoreEmployees() {
        this.set('amountToShow', this.get('amountToShow') + 50);
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/mixins/required-fields-validation-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Mixin.create({
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug(this.get('moduleSlug'));
    },

    requiredFields: Ember.computed('module', 'model.requiredFiledsChanged', function () {
      var _this = this;

      var required = structuredClone(this.get('defaultRequiredFields'));

      if (Ember.isEmpty(this.get('module.settings.fields.required'))) {
        return required;
      }

      Object.keys(this.get('module.settings.fields.required')).forEach(function (field) {
        var requiredExtra = _this.get('module.settings.fields.required');

        if (requiredExtra[field] && required.indexOf(field) === -1) {
          required.push(field);
        }
      });
      return required;
    }),
    actions: {
      hasRequiredFields: function hasRequiredFields() {
        var _this$get, _this$get2;

        var readableFields = [];
        var model = this.get('model');
        var requiredFields = this.get('requiredFields');
        var ignoredFields = (_this$get = this.get('ignoredFields')) !== null && _this$get !== void 0 ? _this$get : []; // In case model-key names are different from the settings-key names

        var distinctNamedFields = (_this$get2 = this.get('distinctNamedFields')) !== null && _this$get2 !== void 0 ? _this$get2 : [];

        for (var i = 0; i < requiredFields.length; i++) {
          for (var o = 0; o < distinctNamedFields.length; o++) {
            if (requiredFields[i] == distinctNamedFields[o].id) {
              requiredFields[i] = distinctNamedFields[o].name;
            }
          }
        } // Loop required fields


        for (var _i = 0, len = requiredFields.length; _i < len; _i++) {
          var _model$get;

          var field = (_model$get = model.get(requiredFields[_i])) !== null && _model$get !== void 0 ? _model$get : null; // In case variable is Object, validate by ID or length if has with Array

          if (_typeof(model.get(requiredFields[_i])) === 'object') {
            var object = model.get(requiredFields[_i]); // In case is array

            if (object != null && object.length > 0) {
              field = object.length;
            } // In case is object


            if (object != null && object.hasOwnProperty('id') && object.get('id')) {
              field = object.get('id');
            }
          } // Fields exceptions from validation


          var complainantInfoFieldException = requiredFields[_i] == 'complainant_information' && model.get('complainant_full_name') != 'deprecated' ? false : true;
          var ignoreField = ignoredFields.includes(requiredFields[_i]); // Don't validate fields in this array
          // Validate

          if (Ember.isBlank(field) && complainantInfoFieldException && !ignoreField) {
            var defaultRequiredFieldsTranslation = this.get('defaultRequiredFieldsTranslation');
            var defaultTranslation = !Ember.isBlank(defaultRequiredFieldsTranslation) && !Ember.isBlank(defaultRequiredFieldsTranslation[requiredFields[_i]]) ? defaultRequiredFieldsTranslation[requiredFields[_i]] : null;

            var pcaseField = requiredFields[_i].replace('_id', '');

            pcaseField = pcaseField.replace(/_/g, ' ').replace('.', ' ').replace(/\w+/g, function (a) {
              return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase();
            });
            console.log('requiredFields: ', requiredFields[_i]);
            pcaseField = pcaseField.replace(' Id', '');

            if (defaultTranslation) {
              pcaseField = this.get('intl').t(this.get('moduleSlug') + '.labels.' + defaultTranslation);
            } else {
              if (this.get('moduleSlug')) {
                pcaseField = this.get('intl').t(this.get('moduleSlug') + '.labels.' + pcaseField);
              }
            }

            readableFields.push(pcaseField);
          }
        }

        if (readableFields.length > 0) {
          this.set('isValid', false);
          var fm = this.get('flashMessages');

          if (readableFields) {
            fm.clearMessages();
            fm.danger('These required fields are missing:', {
              sticky: true
            });

            for (var _i2 = 0, _len = readableFields.length; _i2 < _len; _i2++) {
              fm.danger(readableFields[_i2], {
                sticky: true
              });
            }
          }
        } else {
          this.set('isValid', true);
        }
      }
    }
  });

  _exports.default = _default;
});
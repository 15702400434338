define("isolocity/pods/inspections/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NouMYQgH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n    \"],[11,\"form\"],[4,[38,3],[[32,0],\"create\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n        \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n            \"],[1,[30,[36,4],null,[[\"goBack\"],[[30,[36,3],[[32,0],\"goBack\"],null]]]]],[2,\"\\n            \"],[10,\"h1\"],[12],[2,\"Create \"],[1,[30,[36,0],[\"inspections\",\"singular\",\"Inspection\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n            \"],[19,\"inspections/formcreate\",[]],[2,\"\\n        \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,7],[[35,6],[35,5]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[14,2,\"Creating...\"],[14,\"disabled\",\"true\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[15,2,[31,[\"Create \",[30,[36,0],[\"inspections\",\"singular\",\"Inspection\"],null]]]],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"module-text\",\"isLoading\",\"if\",\"action\",\"back-link\",\"canCreateInspections\",\"hasDrawingLoaded\",\"and\"]}",
    "meta": {
      "moduleName": "isolocity/pods/inspections/new/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/shipments/new/controller", ["exports", "isolocity/mixins/upload-files-mixin", "isolocity/mixins/history-handler-mixin", "isolocity/mixins/shipment-mixin", "isolocity/mixins/required-fields-mixin"], function (_exports, _uploadFilesMixin, _historyHandlerMixin, _shipmentMixin, _requiredFieldsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_requiredFieldsMixin.default, _historyHandlerMixin.default, _shipmentMixin.default, _uploadFilesMixin.default, {
    constants: Ember.inject.service('constants'),
    isLoading: false,
    id_prefix: 'new_',
    historyOverride: '',
    isCreate: true,
    actions: {
      create: function create() {
        var _this = this;

        var fm = this.get('flashMessages'),
            model = this.get('model');

        if (this.get('isValid')) {
          this.set('showSaveErrors', false);

          if (!this.hasValidPartLineItems()) {
            var partText = this.get('constants').getModuleText('parts', 'singular', 'Part', 'lower');
            fm.danger('Please ensure you have selected a valid ' + partText + ' and quantity for each line item');
            this.set('showSaveErrors', true);
            return;
          }

          this.set('isLoading', true);
          model.save().then(function (shipment) {
            _this.send('saveAttachments', shipment);
          }, function (error) {
            _this.set('isLoading', false);

            fm.danger(error.message);
          });
        } else {
          this.send('showErrors');
        }
      },
      insertRow: function insertRow(item) {
        //check if valid
        if (Ember.isEmpty(item.get('shipment_id')) || Ember.isBlank(item.get('quantity'))) return; //push new item to list

        var items = this.get('model.items');
        items.pushObject(item);
        item.setProperties({
          'order': items.get('length'),
          'edittable': false,
          'alwaysEdittable': false
        });
        this.set('model.emptyRow', this.store.createRecord('shipment.item', {
          serial_number: '',
          part_id: null,
          alwaysEdittable: true
        }));
      },
      saveRow: function saveRow(item) {
        item.set('edittable', false);
      },
      selectedOrder: function selectedOrder() {
        var _this2 = this;

        if (this.get('model.order_id.po_number')) {
          this.set('model.po_number', this.get('model.order_id.po_number'));
        }

        if (this.get('model.order_id.contact_id')) {
          this.set('model.customer_id', this.get('model.order_id.contact_id'));
        }

        if (this.get('model.order_id.requested_ship_date')) {
          this.set('model.requested_ship_date', this.get('model.order_id.requested_ship_date'));
        }

        if (this.get('model.order_id.actual_ship_date')) {
          this.set('model.actual_ship_date', this.get('model.order_id.actual_ship_date'));
        }

        this.store.query('order.item', {
          'order_id': this.get('model.order_id.id')
        }).then(function (items) {
          if (items.get('length') > 0) {
            _this2.set('model.items', []);

            items.forEach(function (item, index) {
              var shipmentItem = _this2.store.createRecord('shipment.item', {
                part_id: item.get('part_id'),
                drawing_id: item.get('drawing_id'),
                serial_number: item.get('serial_number'),
                quantity: item.get('quantity'),
                order: item.get('order'),
                alwaysEdittable: false,
                edittable: false
              });

              _this2.get('model.items').pushObject(shipmentItem);
            });
          }
        });
      },
      afterAttachmentsSaved: function afterAttachmentsSaved(shipment) {
        var _this3 = this;

        var fm = this.get('flashMessages');
        var model = this.get('model');

        if (model.get('emptyRow') && model.get('emptyRow.alwaysEdittable')) {
          this.send('insertRow', model.get('emptyRow'));
        }

        if (model.get('items.length') === 0) {
          this.set('isLoading', false);
          this.get('history').setRoute('shipments');
          this.transitionToRoute('shipments.edit', shipment);
          fm.success('A new shipment has been successfully created.');
        } else {
          model.get('items').forEach(function (item) {
            item.set('shipment_id', shipment);

            if (item === model.get('items.lastObject')) {
              item.save().then(function (saved) {
                _this3.set('isLoading', false);

                _this3.get('history').setRoute('shipments');

                _this3.transitionToRoute('shipments.edit', shipment);

                fm.success('A new shipment has been successfully created.');
              });
            } else {
              item.save();
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/deviations/index/controller", ["exports", "isolocity/mixins/date-search-mixin", "isolocity/mixins/floating-sidebar-mixin", "isolocity/mixins/downloadable-reports-mixin", "isolocity/mixins/pagination-mixin", "isolocity/mixins/localization-mixin"], function (_exports, _dateSearchMixin, _floatingSidebarMixin, _downloadableReportsMixin, _paginationMixin, _localizationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_dateSearchMixin.default, _floatingSidebarMixin.default, _downloadableReportsMixin.default, _paginationMixin.default, _localizationMixin.default, {
    modules: Ember.inject.service('modules'),
    search: Ember.inject.service('search.deviations'),
    intl: Ember.inject.service(),
    sortedModel: Ember.computed.sort('model.deviations', 'sortProperties'),
    sortProperties: ['hasIncompleteActionItems:desc', 'has_unread_notes:desc', 'is_closed:asc', 'created_at:desc'],
    paretoOverviewOptions: [],
    selectedParetoOverview: '',
    sourceOverviewOptions: [],
    selectedSourceOverview: '',
    isPlannedUnplannedColumnVisible: Ember.computed('modules', function () {
      var complaintModule = this.get('modules').getModuleBySlug('deviations');
      return complaintModule.settings.is_planned_unplanned_column_visible;
    }),
    allReportData: Ember.computed('allStartDate', 'allEndDate', 'allStatus', 'allType', function () {
      return {
        type: this.get('allType') ? this.get('allType.id') : null,
        status: this.get('allStatus') ? this.get('allStatus') : null,
        start_date: this.get('allStartDate') ? moment(this.get('allStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('allEndDate') ? moment(this.get('allEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    sourceReportData: Ember.computed('sourceStartDate', 'sourceEndDate', 'source', 'selectedSourcePart', 'selectedSourceErrorCode', 'selectedSourceType', function () {
      return {
        type: this.get('selectedSourceType') ? this.get('selectedSourceType.id') : null,
        source: this.get('source') ? this.get('source') : null,
        part_id: this.get('selectedSourcePart') ? this.get('selectedSourcePart.id') : null,
        error_code_id: this.get('selectedSourceErrorCode') ? this.get('selectedSourceErrorCode.id') : null,
        start_date: this.get('sourceStartDate') ? moment(this.get('sourceStartDate')).format("YYYY-MM-DD") : null,
        end_date: this.get('sourceEndDate') ? moment(this.get('sourceEndDate')).format("YYYY-MM-DD") : null
      };
    }),
    isApproved: null,
    approvedOptions: [{
      id: 'Approved',
      name: 'Approved'
    }, {
      id: 'Rejected',
      name: 'Rejected'
    }, {
      id: 'Pending',
      name: 'Pending'
    }, {
      id: 'Closed',
      name: 'Closed'
    }],
    isPlanned: null,
    plannedOptions: [{
      id: true,
      name: 'Planned'
    }, {
      id: false,
      name: 'Unplanned'
    }],
    searchDeviationType: null,
    searchErrorCode: null,
    searchOwner: null,
    init: function init() {
      this.paretoOverviewOptions = [this.get('intl').t('lookups.Error Code'), this.get('intl').t('lookups.Part'), this.get('intl').t('lookups.Type')];
      this.selectedParetoOverview = this.get('intl').t('lookups.Error Code');
      this.sourceOverviewOptions = [this.get('intl').t('lookups.Equipment Reports'), this.get('intl').t('lookups.Audit Reports'), this.get('intl').t('lookups.Non-Conformance Reports')];
      this.selectedSourceOverview = this.get('intl').t('lookups.Equipment Reports');

      this._super.apply(this, arguments);
    },
    actions: {
      addCustomSearchCriteria: function addCustomSearchCriteria() {
        this.get('search').setIsApproved(this.get('isApproved.id'));
        this.get('search').setIsPlanned(this.get('isPlanned.id'));
        this.get('search').setDeviationType(this.get('searchDeviationType.id'));
        this.get('search').setErrorCode(this.get('searchErrorCode.id'));
        this.get('search').setOwner(this.get('searchOwner.id'));
      },
      clearCustomSearchCriteria: function clearCustomSearchCriteria() {
        this.set('isApproved', null);
        this.set('isPlanned', null);
        this.set('searchDeviationType', null);
        this.set('searchErrorCode', null);
        this.set('searchOwner', null);
      },
      handleSelectedParetoOverview: function handleSelectedParetoOverview() {
        if (this.get('selectedParetoOverview') === this.get('intl').t('lookups.Error Code')) {
          this.set('selectedParetoErrorCode', null);
        }

        if (this.get('selectedParetoOverview') === this.get('intl').t('lookups.Part')) {
          this.set('selectedParetoPart', null);
        }

        if (this.get('selectedParetoOverview') === this.get('intl').t('lookups.Type')) {
          this.set('selectedParetoType', null);
        }
      },
      handleSelectedParetoPart: function handleSelectedParetoPart() {
        this.set('selectedParetoOverview', this.get('intl').t('lookups.Part'));
        this.set('selectedParetoErrorCode', null);
        this.set('selectedParetoType', null);
      },
      handleSelectedParetoErrorCode: function handleSelectedParetoErrorCode() {
        this.set('selectedParetoOverview', this.get('intl').t('lookups.Error Code'));
        this.set('selectedParetoPart', null);
        this.set('selectedParetoType', null);
      },
      handleSelectedParetoType: function handleSelectedParetoType() {
        this.set('selectedParetoOverview', this.get('intl').t('lookups.Type'));
        this.set('selectedParetoPart', null);
        this.set('selectedParetoErrorCode', null);
      }
    }
  });

  _exports.default = _default;
});
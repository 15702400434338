define("isolocity/pods/users/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iCwz4DFr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"overlay-element\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[4,[38,1],[[32,0],\"create\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"header\"],[14,0,\"top\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"goBack\"],[[30,[36,1],[[32,0],\"goBack\"],null]]]]],[2,\"\\n        \"],[10,\"h1\"],[12],[2,\"Create User\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-primary\"],[12],[2,\"\\n\\n      \"],[19,\"users/form\",[]],[2,\"\\n\\n    \"],[13],[3,\" /element-primary \"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"element-footer\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"input\"],[14,0,\"btn btn-go btn-disabled pull-right\"],[14,2,\"Creating...\"],[14,\"disabled\",\"true\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"input\"],[14,0,\"btn btn-go pull-right\"],[14,2,\"Create User\"],[14,4,\"submit\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,6],null,[[\"promise\",\"confirm\",\"cancel\",\"title\"],[[35,5],\"Okay\",false,\"You have successfully added a new user\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"Thanks for adding a new user with the primary role of \"],[10,\"b\"],[12],[1,[34,0]],[13],[2,\".\"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"Your next bill will be updated accordingly.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"newRoleSelected\",\"action\",\"back-link\",\"isLoading\",\"if\",\"showBillingPopup\",\"confirm-popup\"]}",
    "meta": {
      "moduleName": "isolocity/pods/users/new/template.hbs"
    }
  });

  _exports.default = _default;
});
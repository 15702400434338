define("isolocity/mixins/suppliers-mixin", ["exports", "isolocity/mixins/action-item-mixin", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/required-fields-mixin", "isolocity/mixins/owners-and-subscribers-mixin"], function (_exports, _actionItemMixin, _eSignatureMixin, _requiredFieldsMixin, _ownersAndSubscribersMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_requiredFieldsMixin.default, _actionItemMixin.default, _eSignatureMixin.default, _ownersAndSubscribersMixin.default, {
    requiredFields: ['name', 'primary_name', 'primary_phone', 'primary_email', 'primary_position'],
    selectedCertificate: Ember.computed('model.certificates', function () {
      if (!Ember.isEmpty(this.get('model.certificates'))) {
        return this.get('model.certificates.firstObject');
      }

      return null;
    }),
    modules: Ember.inject.service('modules'),

    get module() {
      return this.get('modules').getModuleBySlug('suppliers');
    },

    actions: {
      save: function save() {
        var _this = this;

        var fm = this.get('flashMessages');

        if (this.get('isValid')) {
          if (Ember.isBlank(this.get('model.owners')) && this.get('module.settings.is_authorized_users')) {
            fm.danger('At least one authorized user is required');
            return;
          }

          this.set('isLoading', true);
          this.get('model').save().then(function (supplier) {
            _this.get('model.certificates').forEach(function (certificate) {
              certificate.save();
            });

            _this.get('model.all_action_items').forEach(function (action) {
              action.set('entity_id', supplier.get('id'));
              action.save();
            });

            _this.send('saveAttachments', supplier);
          }, function (response) {
            _this.set('isLoading', false);

            _this.send('showServerErrors', response);
          });
        } else {
          this.send('showErrors');
        }
      },
      setSelectedCertificate: function setSelectedCertificate(certificate) {
        this.set('selectedCertificate', certificate);
      },
      removeCertificate: function removeCertificate(certificate) {
        certificate.set('isDeleting', true);
        certificate.destroyRecord();
      },
      createSupplierActionItem: function createSupplierActionItem(item) {
        item.set('link', 'suppliers.edit');
        item.set('entity_type', 'supplier');

        if (this.get('isEdit')) {
          item.set('entity_id', this.get('model.id'));
        }

        item.set('code', 'supplier_action');
        this.get('model.all_action_items').pushObject(item);

        if (this.get('isEdit')) {
          item.save();
        }
      }
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/components/document-linked-drawings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D1BaIKu2",
    "block": "{\"symbols\":[\"drawing\",\"index\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"app--card none\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"drawings.edit\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,5,\"color: #333; padding-right: 1em;\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n            \"],[11,\"a\"],[24,5,\"padding: 2em 2em 2em 0em;\"],[4,[38,0],[[32,0],\"removeLinkedTemplate\",[32,1]],null],[12],[2,\"Remove\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"link-to\",\"drawings\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "isolocity/pods/components/document-linked-drawings/template.hbs"
    }
  });

  _exports.default = _default;
});
define("isolocity/pods/document-type/-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UB6HMLJn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[1,[34,0]],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"value\",\"id\",\"placeholder\"],[[35,1,[\"name\"]],\"name\",\"Document Type Name\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[2,\"Name Abbreviation\"],[13],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"value\",\"id\",\"placeholder\"],[[35,1,[\"name_abbreviation\"]],\"name_abbreviation\",\"Document Type Name Abbreviation\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[2,\"Status\"],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"id\",\"status\",\"radioName\",\"onActiveChange\"],[[30,[36,4],[[35,3],\"is_active\"],null],[35,1,[\"is_active\"]],\"is_active\",\"updateStatus\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-third\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"status\"],[12],[2,\"Document Validity Footer\"],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"id\",\"status\",\"radioName\",\"onActiveChange\"],[[30,[36,4],[[35,3],\"enable_document_validity_footer\"],null],[35,1,[\"enable_document_validity_footer\"]],\"enable_document_validity_footer\",\"updateStatus\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"flash-messages\",\"model\",\"input\",\"id_prefix\",\"concat\",\"active-inactive-toggle\"]}",
    "meta": {
      "moduleName": "isolocity/pods/document-type/-form/template.hbs"
    }
  });

  _exports.default = _default;
});
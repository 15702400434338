define("isolocity/pods/parts/edit/route", ["exports", "isolocity/mixins/authentication-routing-mixin"], function (_exports, _authenticationRoutingMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticationRoutingMixin.default, {
    modules: Ember.inject.service('modules'),
    model: function model(params) {
      return this.store.findRecord('part', params.part_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      model.set('drawing_id', model.get('drawings.firstObject'));
      model.set('allDrawings', this.store.query('drawing', {
        is_latest_revision: true
      }));
      model.set('allTypes', this.store.findAll('part-type'));
      model.set('parts', this.store.findAll('part'));
      model.set('suppliers', this.store.findAll('supplier'));
      model.set('customers', this.store.findAll('customer'));
      model.set('equipments', this.store.findAll('equipment'));
      model.set('departments', this.store.findAll('department'));
      model.reloadRelations();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('isLatestRevision', true);
      controller.set('isObsolete', false);
      var usePartRevisions = this.get('modules').getModuleSetting('parts', 'use_part_revisions', false);
      controller.set('usePartRevisions', usePartRevisions);

      if (usePartRevisions) {
        controller.set('isLocked', model.is_approved);
      } else {
        controller.set('isLocked', false);
      }
    },
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          return this.transitionTo('parts');
        }
      },
      loading: function loading(transition, originRoute) {
        this.intermediateTransitionTo('loading');
      }
    }
  });

  _exports.default = _default;
});
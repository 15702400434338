define("isolocity/mixins/enrollment-edit-mixin", ["exports", "isolocity/mixins/e-signature-mixin", "isolocity/mixins/rendered-form-mixin", "isolocity/mixins/file-download-mixin"], function (_exports, _eSignatureMixin, _renderedFormMixin, _fileDownloadMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_renderedFormMixin.default, _fileDownloadMixin.default, _eSignatureMixin.default, {
    activeEnrollment: null,
    formToLoadId: null,
    // List of file id which user has opened
    fileRead: [],

    get isEsignatureRequiredForTraining() {
      return this.get('modules').getModuleSetting('training-schedules', 'is_esignature_required_employee_training', false);
    },

    // Flag will indicate if user has read all the file
    userReadAllFile: false,
    hasFiles: Ember.computed('activeEnrollment.training_schedule_files.length', 'stickiedFiles.length', 'activeEnrollment.document_links', function () {
      return this.get('activeEnrollment.training_schedule_files.length') > 0 || this.get('stickiedFiles.length') > 0 || this.get('activeEnrollment.document_links.length') > 0;
    }),
    stickiedFiles: Ember.computed('activeEnrollment.training_schedule_id.training_program_id', 'activeEnrollment.training_schedule_id.document_id', function () {
      var files = [];

      if (!Ember.isBlank(this.get('activeEnrollment.training_program_files'))) {
        files = files.concat(this.get('activeEnrollment.training_program_files'));
      }

      if (!Ember.isBlank(this.get('activeEnrollment.document_files'))) {
        var docFiles = this.get('activeEnrollment.document_files');
        files = files.concat(docFiles);
      }

      return files;
    }),
    hasReadAllDocuments: function hasReadAllDocuments() {
      var validate = true,
          enrollmentEditMixin = this; // Check if all file has opend by user
      // By checking file id in fileRead array
      // If not then restrict user to submit the form

      if (!Ember.isBlank(this.get('activeEnrollment.training_schedule_files'))) {
        this.get('activeEnrollment.training_schedule_files').map(function (file) {
          if (enrollmentEditMixin.get('fileRead').indexOf(file.id) == -1) {
            validate = false;
          }
        });
      }

      if (!Ember.isBlank(this.get('activeEnrollment.training_program_files'))) {
        this.get('activeEnrollment.training_program_files').map(function (file) {
          if (enrollmentEditMixin.get('fileRead').indexOf(file.id) == -1) {
            validate = false;
          }
        });
      }

      if (!Ember.isBlank(this.get('activeEnrollment.document_files'))) {
        this.get('activeEnrollment.document_files').map(function (file) {
          if (enrollmentEditMixin.get('fileRead').indexOf(file.id) == -1) {
            validate = false;
          }
        });
      }

      return validate;
    },
    actions: {
      toggleIsCompleted: function toggleIsCompleted() {
        if (!this.hasReadAllDocuments()) {
          this.set('activeEnrollment.is_completed', false);
          var fm = this.get('flashMessages');
          fm.danger('Please read all the attached resource materials before completing the training.');
        }
      },
      expandEnrollment: function expandEnrollment(enrollment) {
        this.set('confirmBtnText', 'Save');
        this.set('enrollmentFormTitle', enrollment.get('training_schedule.display_name'));

        if (enrollment.get('is_completed')) {
          this.set('confirmBtnText', false);
        }

        this.set('fileRead', []);
        this.set('userReadAllFile', false);
        this.send('showEnrollmentForm', enrollment);
      },
      showEnrollmentForm: function showEnrollmentForm(enrollment) {
        var _this = this;

        enrollment.set('isLoadingForm', true);
        enrollment.reload().then(function (item) {
          enrollment.set('isLoadingForm', false);

          _this.set('activeEnrollment', item);

          _this.set('formToLoadId', item.get('training_schedule_id'));

          var defer = Ember.RSVP.defer();
          defer.promise.then(function (data) {
            if (_this.hasReadAllDocuments()) {
              _this.set('userReadAllFile', true);

              _this.set('showEnrollmentFormPromise', null);

              _this.send('eSignature', 'submitChecklistsAnswers', null, _this.get('isEsignatureRequiredForTraining'));
            } else {
              _this.resetFormData();

              _this.set('showEnrollmentFormPromise', null);

              var fm = _this.get('flashMessages');

              fm.danger('Please read all the attached resource materials before submitting the training.');
            }
          }, function () {
            enrollment.rollbackAttributes();

            _this.set('showEnrollmentFormPromise', null);
          });

          _this.set('showEnrollmentFormPromise', defer);
        });
      },
      submitChecklistsAnswers: function submitChecklistsAnswers() {
        var _this2 = this;

        var fm = this.get('flashMessages');
        var authHeaders = this.get('authHeaders');
        var resultObject = this.getResultObject();
        $.ajax({
          headers: authHeaders,
          url: this.getApiUrl('/training-schedule-employees/' + this.get('activeEnrollment.id') + '/results'),
          method: 'POST',
          data: {
            password: this.get('eSignaturePassword'),
            result: JSON.stringify(resultObject),
            trainingSchedule: this.get('activeEnrollment.training_schedule')
          }
        }).then(function () {
          _this2.set('activeEnrollment.result', JSON.stringify(resultObject));

          _this2.get('activeEnrollment').save().catch(function (error) {
            var fm = _this2.get('flashMessages');

            var errorMessage = error['errors'][0]['detail'];

            _this2.set('activeEnrollment.is_completed', false);

            fm.danger(errorMessage);
          });
        }).fail(function () {
          var message = 'An error occurred while trying to submit the checklist results.';

          if (_this2.get('isEsignatureRequiredForTraining')) {
            message += ' Please ensure that your password is correct.';
          }

          _this2.set('activeEnrollment.is_completed', false);

          _this2.resetFormData();

          fm.danger(message);
        }).always(function () {
          _this2.set('eSignaturePassword', '');
        });
      }
    }
  });

  _exports.default = _default;
});